/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { Alert, Modal, Typography } from 'antd';
import { docsURL } from '../scripts/GetDocsURL';
import { Trans, useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

interface SplashModalProps {
    isVisible: boolean;
    handleCancel: any;
}

/**
 * The Splash modal. Opens when there is version drift between the client and server.
 */
export function SplashModal(props: SplashModalProps): any {
    const { t } = useTranslation();
    return (
        <Modal title={''} visible={props.isVisible} onCancel={props.handleCancel} footer={null} bodyStyle={{ padding: 0 }}>
            <Alert
                type="error"
                showIcon
                message={t('splashmodal.versiondrift.message')}
                description={
                    <div>
                        <Paragraph>{t('splashmodal.versiondrift.description')}</Paragraph>
                        <Paragraph>
                            <Trans i18nKey="splashmodal.versiondrift.referto">
                                <a target="_blank" href={`${docsURL}/getting-started/installation/index.html`}></a>
                            </Trans>
                        </Paragraph>
                    </div>
                }
            />
        </Modal>
    );
}

export function SplashPatchModal(props: SplashModalProps): any {
    const { t } = useTranslation();
    return (
        <Modal title={''} visible={props.isVisible} onCancel={props.handleCancel} footer={null} bodyStyle={{ padding: 0 }}>
            <Alert
                type="info"
                showIcon
                message={t('splashmodal.patchupdate.message')}
                description={
                    <div>
                        <Paragraph>{t('splashmodal.patchupdate.description')}</Paragraph>
                        <Paragraph>
                            <Trans i18nKey="splashmodal.patchupdate.referto">
                                <a target="_blank" href={`${docsURL}/getting-started/installation/index.html`}></a>
                            </Trans>
                        </Paragraph>
                    </div>
                }
            />
        </Modal>
    );
}
