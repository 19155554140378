'use strict';

import { Alert, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React from 'react';
import { AgentModel } from './AgentWidget';

export default function AgentWidgetSettings(t: any, availableAgents: AgentModel[], setSelectedAgents: (value: string[]) => void, selectedAgents: string[], cacheUpdating: boolean) {
    return (
        <div>
            <Form.Item label={t('widgetsettingsform.agentwidget.agentlabel.label')} name="agentlabel">
                <Input />
            </Form.Item>

            <Form.Item
                label={t('widgetsettingsform.agentwidget.agents.label')}
                name="agents"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.agentwidget.agents.required'),
                    },
                ]}
            >
                <Select
                    placeholder={t('widgetsettingsform.agentwidget.agents.placeholder')}
                    mode="multiple"
                    allowClear
                    value={selectedAgents}
                    onChange={setSelectedAgents}
                    filterOption={(input, option) => {
                        let find = option.props.children.find((t) => {
                            return t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        });

                        return find != null;
                    }}
                >
                    {availableAgents.map((a) => (
                        <Select.Option key={a.id} value={a.id}>
                            {a.identity ? `${a.identity.name} (${a.username})` : a.username}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {cacheUpdating && (
                <Form.Item>
                    <Alert message={t('widgetsettingsform.cachesync.agentwarning')} type="warning" showIcon />
                </Form.Item>
            )}
            <Row gutter={8}>
                <Form.Item label={t('widgetsettingsform.agentwidget.showagentid.label')} name="showagentid" valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        label={t('widgetsettingsform.agentwidget.valuesize.label')}
                        name="valuesize"
                        rules={[
                            {
                                required: true,
                                message: t('widgetsettingsform.agentwidget.valuesize.required'),
                            },
                        ]}
                    >
                        <InputNumber min={12} max={48} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t('widgetsettingsform.agentwidget.headingsize.label')}
                        name="headingsize"
                        rules={[
                            {
                                required: true,
                                message: t('widgetsettingsform.agentwidget.headingsize.required'),
                            },
                        ]}
                    >
                        <InputNumber min={12} max={48} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
