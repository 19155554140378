import * as React from 'react';

import { Divider, Modal, Typography } from 'antd';
const { Paragraph } = Typography;

// Localisation:
import { Trans, useTranslation } from 'react-i18next';
import Branding from '../components/Branding';
import { docsURL } from '../scripts/GetDocsURL';

interface HelpModalProps {
    isVisible: boolean;
    handleOk: () => void;
    handleCancel: any;
    version: string;
}

/**
 * The Help modal. Opens when clicking the help button in the header.
 */
function HelpModal(props: HelpModalProps) {
    const { t } = useTranslation();

    return (
        <Modal title={t('helpmodal.title')} visible={props.isVisible} onOk={props.handleOk} onCancel={props.handleCancel}>
            <Paragraph style={{ textAlign: 'center' }}>
                <Trans i18nKey="helpmodal.assistancemessage">
                    <a target="_blank" href={docsURL}></a>
                </Trans>
            </Paragraph>
            <Divider />
            <Paragraph style={{ textAlign: 'center' }}>{t('helpmodal.versionlabel', { version: props.version })}</Paragraph>
            <Divider />
            <Branding />
        </Modal>
    );
}

export default HelpModal;
