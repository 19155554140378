'use strict';

import { Popover, Space, Typography } from 'antd';
import { CheckCircleOutlined, LoadingOutlined, PoweroffOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface ConnectionLightProps {
    connected: boolean;
}

/**
 * The connection indicator in the header that turns green when connected, and red otherwise.
 */
function ConnectionLight(props: ConnectionLightProps) {
    const { t } = useTranslation();

    return (
        <Popover
            content={
                <Text style={{ fontSize: '12px', color: props.connected ? '#52c41a' : '#ff4d4f' }}>
                    {props.connected ? (
                        <Space>
                            <CheckCircleOutlined /> {t('connection.connected.label')}
                        </Space>
                    ) : (
                        <Space>
                            <LoadingOutlined /> {t('connection.disconnected.label')}
                        </Space>
                    )}
                </Text>
            }
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px',
                    width: '32px',
                }}
            >
                <PoweroffOutlined style={{ color: props.connected ? '#52c41a' : '#ff4d4f' }} />
            </div>
        </Popover>
    );
}

export default ConnectionLight;
