import * as React from 'react';
import { useState } from 'react';

import { Form, Modal, Radio } from 'antd';

import { WidgetConfig, WidgetType } from '../components/WidgetGrid';
import WidgetSettingsForm from '../components/WidgetSettingsForm';
import { AgentModel, AgentWidgetConfig } from '../widgets/agent/AgentWidget';
import { AgentColumnConfig } from '../widgets/agent/EditAgentColumnModal';
import { ChartWidgetConfig } from '../widgets/chart/ChartWidget';
import { GaugeWidgetConfig } from '../widgets/gauge/GaugeWidget';
import { LabelWidgetConfig } from '../widgets/label/LabelWidget';
import { MetricWidgetConfig } from '../widgets/metric/MetricWidget';
import { TableWidgetConfig } from '../widgets/table/TableWidget';

import { debuglog } from '../scripts/debugLog';

// Localisation:
import { useTranslation } from 'react-i18next';

interface MetricSelectionProps {
    isVisible: boolean;
    handleOk: (para: WidgetConfig) => void;
    handleCancel: any;
    availableMetrics: string[];
    availableSlaThresholds: string[];
    queueList: { name: string; id: string }[];
    widgetConfigs: WidgetConfig[];
    availableAlarms: string[];
    openAlarmModal: () => void;
    availableAgents: AgentModel[];
    cacheUpdating: boolean;
}

/**
 * The Add Widget modal. Opens when adding a new widget to the Widget Grid.
 */
function AddWidgetModal(props: MetricSelectionProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [widgetType, setWidgetType] = useState<WidgetType>();
    const [iterator, setIterator] = useState(0);

    async function handleOk() {
        try {
            var i = iterator;
            const values = await form.validateFields();

            debuglog('Success', values);
            var config: WidgetConfig;

            while (props.widgetConfigs.some((wc) => wc.widgetid === values.type + i)) {
                debuglog(values.type + i);
                i += 1;
            }

            if (values.type === 'metric') {
                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Metric,
                    config: {
                        metricid: values.metricid,
                        queues: values.queues,
                        label: values.metriclabel,
                        slaThreshold: values.slaThreshold,
                        amberThreshold: values.metricamber || 20,
                        redThreshold: values.metricred || 40,
                        amberSound: values.metricambersound || '',
                        redSound: values.metricredsound || '',
                        blink: values.metricblink || false,
                        sla: '',
                    } as MetricWidgetConfig,
                };
            } else if (values.type === 'label') {
                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Label,
                    config: {
                        metricid: '',
                        queues: [],
                        label: values.labeltext,
                        size: values.labelsize,
                        hidebackground: values.hidebackground,
                    } as LabelWidgetConfig,
                };
            } else if (values.type === 'chart') {
                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Chart,
                    config: {
                        metricid: values.metricid,
                        queues: values.queues,
                        label: values.chartlabel,
                        slaThreshold: values.slaThreshold,
                        timerange: values.charttimerange,
                    } as ChartWidgetConfig,
                };
            } else if (values.type === 'table') {
                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Table,
                    config: {
                        metricid: '',
                        queues: values.queues,
                        label: values.tablelabel,
                        selectedMetrics: values.metricids,
                        columnConfigs: {},
                        valuesize: values.valuesize,
                        headingsize: values.headingsize,
                    } as TableWidgetConfig,
                };
            } else if (values.type === 'gauge') {
                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Gauge,
                    config: {
                        metricid: values.metricid,
                        queues: values.queues,
                        label: values.gaugelabel,
                        slaThreshold: values.slaThreshold,
                        maximum: values.gaugemax || 100,
                        amberThreshold: values.metricamber || 20,
                        redThreshold: values.metricred || 40,
                        amberSound: values.metricambersound || '',
                        redSound: values.metricredsound || '',
                        blink: values.metricblink || false,
                        sla: '',
                    } as GaugeWidgetConfig,
                };
            } else if (values.type === 'agent') {
                const defaultColumns: { [key: string]: AgentColumnConfig } = {};
                defaultColumns['0'] = {
                    heading: t('editagentcolumnmodal.agentmetricselection.metrics.Inbound'),
                    metrics: ['Inbound'],
                };
                defaultColumns['1'] = {
                    heading: t('editagentcolumnmodal.agentmetricselection.metrics.Outbound'),
                    metrics: ['Outbound'],
                };
                defaultColumns['2'] = {
                    heading: t('editagentcolumnmodal.agentmetricselection.metrics.Total'),
                    metrics: ['Total'],
                };

                config = {
                    widgetid: values.type + i,
                    type: WidgetType.Agent,
                    config: {
                        metricid: '',
                        queues: [],
                        label: values.agentlabel,
                        selectedAgents: values.agents,
                        showAgentID: values.showagentid,
                        valuesize: values.valuesize,
                        headingsize: values.headingsize,
                        columnConfigs: defaultColumns,
                    } as AgentWidgetConfig,
                };
            }

            props.handleOk(config);

            form.resetFields();
            setWidgetType(null);

            setIterator(i + 1);
        } catch (errorInfo) {
            debuglog('Failed:', errorInfo);
        }
    }

    return (
        <Modal
            title={t('addwidgetmodal.title')}
            onOk={handleOk}
            onCancel={props.handleCancel}
            okText={t('addwidgetmodal.oktext')}
            visible={props.isVisible}
            destroyOnClose={true}
            forceRender={true}
            width="640px"
        >
            <Form form={form} layout="vertical" name="addWidget">
                <Form.Item
                    label={t('addwidgetmodal.widgettype.label')}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: t('addwidgetmodal.widgettype.required'),
                        },
                    ]}
                >
                    <Radio.Group
                        onChange={(v) => {
                            debuglog('type', v);

                            switch (v.target.value) {
                                case 'label':
                                    setWidgetType(WidgetType.Label);
                                    break;
                                case 'metric':
                                    setWidgetType(WidgetType.Metric);
                                    break;
                                case 'chart':
                                    setWidgetType(WidgetType.Chart);
                                    break;
                                case 'table':
                                    setWidgetType(WidgetType.Table);
                                    break;
                                case 'gauge':
                                    setWidgetType(WidgetType.Gauge);
                                    break;
                                case 'agent':
                                    setWidgetType(WidgetType.Agent);
                                    break;
                            }
                        }}
                    >
                        <Radio.Button key="label" value="label">
                            {t('addwidgetmodal.widgetnames.label')}
                        </Radio.Button>
                        <Radio.Button key="metric" value="metric">
                            {t('addwidgetmodal.widgetnames.metric')}
                        </Radio.Button>
                        <Radio.Button key="chart" value="chart">
                            {t('addwidgetmodal.widgetnames.chart')}
                        </Radio.Button>
                        <Radio.Button key="table" value="table">
                            {t('addwidgetmodal.widgetnames.table')}
                        </Radio.Button>
                        <Radio.Button key="gauge" value="gauge">
                            {t('addwidgetmodal.widgetnames.gauge')}
                        </Radio.Button>
                        <Radio.Button key="agent" value="agent">
                            {t('addwidgetmodal.widgetnames.agent')}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {WidgetSettingsForm({
                    widgetType: widgetType,
                    availableMetrics: props.availableMetrics,
                    availableSlaThresholds: props.availableSlaThresholds,
                    queueList: props.queueList,
                    currentWidget: null,
                    availableAlarms: props.availableAlarms,
                    openAlarmModal: props.openAlarmModal,
                    availableAgents: props.availableAgents,
                    cacheUpdating: props.cacheUpdating,
                })}
            </Form>
        </Modal>
    );
}

export default AddWidgetModal;
