import * as React from 'react';
import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Statistic, Typography } from 'antd';
import tinycolor from 'tinycolor2';
import WidgetButtons from '../../components/WidgetButtons';
import { Metric, WidgetDataConfig } from '../../components/WidgetGrid';

const { Text } = Typography;

// Localisation:
import { useTranslation } from 'react-i18next';

export interface MetricWidgetConfig extends WidgetDataConfig {
    amberThreshold: number;
    redThreshold: number;
    amberSound: string;
    redSound: string;
    blink: boolean;
    sla: string;
}

interface MetricWidgetProps {
    id: string;
    config: MetricWidgetConfig;
    metric: Metric;
    editing: boolean;
    onEditWidgetClicked: any;
    amberColor: string;
    redColor: string;
    queueList: { name: string; id: string }[];
    triggerAlarm: (id: string, alarmSound: string) => void;
    dismissAlarm: (id: string) => void;
}

export function fancyTimeFormat(time) {
    const t = parseInt(time, 10);
    const hrs = ~~(t / 3600);
    const mins = ~~((t % 3600) / 60);
    const secs = ~~t % 60;
    let ret = '';
    if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
}

function MetricWidget(props: MetricWidgetProps) {
    const { t } = useTranslation();

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    const [labelColor, setLabelColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [blink, setBlink] = useState(false);

    const [queueName, setQueueName] = useState('');
    const [value, setValue] = useState('-');

    const [alarming, setAlarming] = useState<boolean>(false);

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    React.useEffect(() => {
        if (!props.queueList || props.queueList.length === 0 || !props.config.queues || props.config.queues.length === 0) return;

        if (props.config.queues.length === 1) {
            var queue = props.queueList.find((q) => q.id === props.config.queues[0]);
            if (queue) setQueueName(queue.name);
        } else {
            setQueueName(t('widgets.common.multiqueue'));
        }

        return;
    }, [props.queueList, props.config.queues]);

    React.useEffect(() => {
        if (!props.metric) return;

        const amber = props.config.amberThreshold || 0;
        const red = props.config.redThreshold || 0;
        const checkLess = red < amber;
        const val = parseInt(props.metric.value);

        if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
            // Red triggered:
            setLabelColor(props.redColor);
            setBackgroundColor(tinycolor(props.redColor).setAlpha(0.2).toString());
            if (props.config.blink) setBlink(true);
            else setBlink(false);

            if (props.config.redSound !== '') {
                props.triggerAlarm(props.id, props.config.redSound);
                setAlarming(true);
            } else {
                props.dismissAlarm(props.id);
                setAlarming(false);
            }
        } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
            // Amber triggered:
            setLabelColor(props.amberColor);
            setBackgroundColor(tinycolor(props.amberColor).setAlpha(0.2).toString());
            setBlink(false);

            setAlarming(false);
            if (props.config.amberSound !== '') {
                props.triggerAlarm(props.id, props.config.amberSound);
                setAlarming(true);
            } else {
                props.dismissAlarm(props.id);
                setAlarming(false);
            }
        } else {
            // No threshold:
            setLabelColor('');
            setBackgroundColor('');
            setBlink(false);
            props.dismissAlarm(props.id);
            setAlarming(false);
        }
    }, [props.metric && props.metric.value, props.config, props.amberColor, props.redColor]);

    React.useEffect(() => {
        if (!props.metric) return;

        //const id = props.id;
        const unit = props.metric.unit;
        let val = props.metric.value;

        // let sla;
        // try {
        //     sla =
        //         thresholds === undefined ||
        //             thresholds.SERVICE_LEVEL.sla == '' ||
        //             thresholds.SERVICE_LEVEL.sla == undefined
        //             ? 120
        //             : thresholds.SERVICE_LEVEL.sla;
        // } catch {
        //     sla = 120;
        // }
        // if (id === 'SERVICE_LEVEL' && metric.Metric.Threshold.ThresholdValue != sla) {
        //     debuglog(
        //         'updateWallboard: SLA does not match',
        //         id,
        //         metric.Metric.Threshold.ThresholdValue,
        //         sla,
        //     );
        //     return;
        // }
        if (unit === 'SECONDS') {
            val = fancyTimeFormat(props.metric.value);
        } else if (unit === 'PERCENT') {
            val = props.metric.value + '%';
        }
        //  else {
        //     if (id == 'CONTACTS_ABANDONED') {
        //         contactsQueued = contactsQueued.Value;
        //         if (contactsQueued == 0) contactsQueued = 1;
        //         val =
        //             (
        //                 parseFloat(
        //                     parseFloat(val).toFixed(2) / parseFloat(contactsQueued),
        //                 ).toFixed(2) * 100
        //             ).toFixed(2) + '%';
        //     } else if (id === 'AGENTS_AVAILABLE') {
        //         val = val + agentsOnCallCount;
        //     }
        // }

        setValue(val);
    }, [props.metric]);

    return (
        <div ref={ref} style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }} className={`data-widget ${blink ? 'blink' : ''}`}>
            <Card
                bodyStyle={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: backgroundColor,
                }}
            >
                <Text>{props.config.label}</Text>
                <Statistic title={queueName} value={value} style={{ textAlign: 'center' }} valueStyle={{ fontSize: Math.min(width, height) / 4, color: labelColor }} />
                <div hidden={!props.config.amberSound && !props.config.redSound}>
                    <BellOutlined style={{ color: labelColor, position: 'absolute', top: '8px', left: '8px' }} className={alarming ? 'ring-icon' : null} />
                </div>

                <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />
            </Card>
        </div>
    );
}

// <div ref={ref} id={props.id + "_CARD"} className={props.green ? "card text-white bg-success" : "card text-white bg-info"} style={{ height: '100%' }}>
// <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
//     <span>{props.name}</span>
//     <span
//         style={{ alignSelf: 'center' }}
//         className="glyphicon glyphicon-cog coggle"
//         data-id={props.id}
//         data-toggle="modal"
//         data-target="#updateThresholdModal"
//     />
// </div>
// <div id={props.id + "_BODY"} className="card-body text-white clearfix small" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//     <span id={props.id} style={{ fontSize: Math.min(width, height) / 4, lineHeight: '0' }}>-</span>
// </div>
// </div>

export default MetricWidget;
