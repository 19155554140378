import { EditOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Space, Statistic, Table } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tinycolor from 'tinycolor2';
import WidgetButtons from '../../components/WidgetButtons';
import { Metric, WidgetDataConfig } from '../../components/WidgetGrid';
import { debuglog } from '../../scripts/debugLog';
import { getDefaultMetricLabel } from '../../scripts/MetricMapping';
import { fancyTimeFormat } from '../metric/MetricWidget';
import EditTableColumnModal, { ColumnConfig } from './EditTableColumnModal';

const { Column } = Table;

export interface TableWidgetConfig extends WidgetDataConfig {
    selectedMetrics: string[];
    columnConfigs: { [metric: string]: ColumnConfig };
    valuesize: number;
    headingsize: number;
}

interface TableWidgetProps {
    id: string;
    config: TableWidgetConfig;
    editing: boolean;
    onEditWidgetClicked: any;
    queueData: { [key: string]: Metric[] };
    queueList: { name: string; id: string }[];
    amberColor: string;
    redColor: string;
    setEditingInner: (v: boolean) => void;
}

interface TableDataQueue {
    queueName: string;
}

function TableWidget(props: TableWidgetProps) {
    const { t } = useTranslation();

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    const [dataSource, setDataSource] = useState<TableDataQueue[]>([]);

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<{ metric: string; config: ColumnConfig }>({
        metric: null,
        config: null,
    });

    function openEditModal(metric: string) {
        if (!props.config.columnConfigs) props.config.columnConfigs = {};

        if (props.config.columnConfigs[metric]) {
            setSelectedColumn({ metric: metric, config: props.config.columnConfigs[metric] });
        } else {
            props.config.columnConfigs[metric] = null;
            setSelectedColumn({ metric: metric, config: null });
        }
        props.setEditingInner(true);
        setEditModalVisible(true);
    }

    function saveColumnConfig(metric: string, config: ColumnConfig) {
        if (metric && config) props.config.columnConfigs[metric] = config;
        props.setEditingInner(false);
        setEditModalVisible(false);
    }

    function renderCell(metric: string, v: any) {
        const value = v[metric];
        let backgroundColor = '';
        let labelColor = '';
        let blink = false;

        if (props.config.columnConfigs[metric]) {
            const amber = props.config.columnConfigs[metric].amberThreshold || 0;
            const red = props.config.columnConfigs[metric].redThreshold || 0;
            const blinkOn = props.config.columnConfigs[metric].blink || false;
            const checkLess = red < amber;
            const val = parseInt(value);

            if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
                labelColor = props.redColor;
                backgroundColor = tinycolor(props.redColor).setAlpha(0.2).toString();
                blink = blinkOn;
            } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
                labelColor = props.amberColor;
                backgroundColor = tinycolor(props.amberColor).setAlpha(0.2).toString();
                blink = false;
            }
        }

        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: backgroundColor,
                    padding: '8px',
                }}
                className={`data-widget ${blink ? 'blink' : ''}`}
            >
                <Statistic
                    value={value}
                    style={{ textAlign: 'center' }}
                    valueStyle={{
                        color: labelColor,
                        fontSize: props.config.valuesize,
                    }}
                />
            </div>
        );
    }

    React.useEffect(() => {
        if (!props.queueList || props.queueList.length === 0 || !props.config.queues || props.config.queues.length === 0 || !props.config.selectedMetrics) return;

        let newDataSource: TableDataQueue[] = [];

        props.config.queues.forEach((q) => {
            let queueRow = {
                key: q,
                queueName: props.queueList.find((qli) => qli.id === q).name,
            };

            props.config.selectedMetrics.forEach((m) => {
                let metric = props.queueData[q]?.find((qd) => qd.name === m);
                if (!metric) return;

                const unit = metric.unit;
                let val = metric.value;

                if (unit === 'SECONDS') {
                    val = fancyTimeFormat(metric.value);
                } else if (unit === 'PERCENT') {
                    val = metric.value + '%';
                }

                queueRow[m] = val;
            });

            newDataSource.push(queueRow);
        });

        newDataSource.sort((a: TableDataQueue, b: TableDataQueue) => {
            return a.queueName.localeCompare(b.queueName);
        });

        debuglog('Table widget queueData:', props.queueData);
        debuglog('Table widget data source:', newDataSource);

        setDataSource(newDataSource);

        return;
    }, [props.queueData, props.config.queues]);

    return (
        <Card
            style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }}
            bodyStyle={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
                padding: 0,
            }}
            bordered={false}
        >
            <Table
                dataSource={dataSource}
                size="middle"
                pagination={false}
                title={props.config.label ? () => <div style={{ fontSize: props.config.headingsize }}>{props.config.label}</div> : null}
                bordered
            >
                <Column
                    title={() => {
                        return <Space style={{ padding: '16px', fontSize: props.config.headingsize }}>{t('widgets.tablewidget.queueheader')}</Space>;
                    }}
                    key="queueName"
                    render={(v, r, i) => {
                        return <div style={{ padding: '8px', fontSize: props.config.headingsize }}>{v.queueName}</div>;
                    }}
                />
                {props.config?.selectedMetrics &&
                    props.config.selectedMetrics.map((m, i) => {
                        return (
                            <Column
                                title={() => {
                                    return (
                                        <Space
                                            style={{
                                                padding: '16px',
                                                fontSize: props.config.headingsize,
                                            }}
                                        >
                                            {getDefaultMetricLabel(t, m)}
                                            {props.editing && (
                                                <Button
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                    }}
                                                    type="text"
                                                    icon={<EditOutlined />}
                                                    onClick={() => openEditModal(m)}
                                                />
                                            )}
                                        </Space>
                                    );
                                }}
                                key={i}
                                render={(v, r, i) => {
                                    return renderCell(m, v);
                                }}
                            />
                        );
                    })}
            </Table>

            <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />

            <EditTableColumnModal isVisible={editModalVisible} handleOk={saveColumnConfig} handleCancel={() => saveColumnConfig(null, null)} selectedColumn={selectedColumn} />
        </Card>
    );
}

export default TableWidget;
