'use strict';

import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Popover, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface SyncLightProps {
    updating: boolean;
}

/**
 * The sync indicator that shows when Amazon Connect cache sync is in progress.
 */
function SyncLight(props: SyncLightProps) {
    const { t } = useTranslation();

    return (
        <Popover
            content={
                <Text style={{ fontSize: '12px' }}>
                    <Space>{props.updating ? t('cachesync.syncing') : t('cachesync.completed')}</Space>
                </Text>
            }
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px',
                    width: props.updating ? '32px' : '0px',
                    transition: props.updating ? 'width 0.5s ease-out 0s' : 'width 0.5s ease-out 2s',
                    overflow: 'hidden',
                }}
            >
                {props.updating ? <SyncOutlined spin style={{ color: 'grey' }} /> : <CheckCircleOutlined style={{ color: '#52c41a' }} />}
            </div>
        </Popover>
    );
}

export default SyncLight;
