import { WarningOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Popconfirm, Row, Select, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { debuglog } from '../../scripts/debugLog';

export interface AgentColumnConfig {
    heading: string;
    metrics: string[];
}

interface EditAgentColumnModalProps {
    isVisible: boolean;
    handleOk: (key: string, config: AgentColumnConfig) => void;
    handleCancel: () => void;
    handleDelete: (key: string) => void;
    selectedColumn: { key: string; config: AgentColumnConfig };
}

const handledMetrics = ['Inbound', 'Outbound', 'Callback', 'Transfer', 'Total'];

function EditAgentColumnModal(props: EditAgentColumnModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [selectedMetrics, setSelectedMetrics] = React.useState<string[]>([]);

    async function handleOk() {
        try {
            var newConfig: AgentColumnConfig = props.selectedColumn.config;
            const values = await form.validateFields();

            debuglog('Success', values);

            newConfig = {
                heading: values.heading,
                metrics: values.metrics,
            } as AgentColumnConfig;

            props.handleOk(props.selectedColumn.key, newConfig);
        } catch (errorInfo) {
            debuglog('Failed:', errorInfo);
        }
    }

    async function handleCancel() {
        props.handleCancel();
    }
    async function handleDelete() {
        props.handleDelete(props.selectedColumn.key);
    }

    // Build initial form values on modal open:
    React.useEffect(() => {
        form.setFieldsValue({
            heading: props.selectedColumn?.config?.heading || '',
            metrics: props.selectedColumn?.config?.metrics || [],
        });
    }, [props.selectedColumn]);

    return (
        <Modal
            title={t('editagentcolumnmodal.title')}
            visible={props.isVisible}
            onCancel={handleCancel}
            footer={[
                <Row justify="space-between">
                    <Popconfirm
                        key="deleteconfirm"
                        title={t('editwidgetmodal.footer.delete.confirm')}
                        onConfirm={handleDelete}
                        okText={t('common.yes')}
                        cancelText={t('common.cancel')}
                        placement="topLeft"
                        icon={<WarningOutlined style={{ color: 'red' }} />}
                    >
                        <Button key="delete" type="primary" danger>
                            {t('editwidgetmodal.footer.delete.label')}
                        </Button>
                    </Popconfirm>
                    <Space>
                        <Button key="back" onClick={handleCancel}>
                            {t('common.cancel')}
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk}>
                            {t('common.save')}
                        </Button>
                    </Space>
                </Row>,
            ]}
        >
            <Form form={form} layout="vertical" name="editAgentColumnForm">
                <Form.Item label={t('editagentcolumnmodal.headinglabel')} name="heading">
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('editagentcolumnmodal.agentmetricselection.label')}
                    name="metrics"
                    rules={[
                        {
                            required: true,
                            message: t('editagentcolumnmodal.agentmetricselection.required'),
                        },
                    ]}
                    tooltip={t('editagentcolumnmodal.agentmetricselection.tooltip')}
                >
                    <Select placeholder={t('editagentcolumnmodal.agentmetricselection.placeholder')} mode="multiple" allowClear value={selectedMetrics} onChange={(v) => setSelectedMetrics(v)}>
                        {handledMetrics.map((a, i) => (
                            <Select.Option key={a} value={a}>
                                {t('editagentcolumnmodal.agentmetricselection.metrics.' + a)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditAgentColumnModal;
