import * as React from 'react';

import { Button, Divider, Modal, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { debuglog } from '../scripts/debugLog';

const { Dragger } = Upload;

// Localisation:
import { useTranslation } from 'react-i18next';

interface QueueConfigModalProps {
    isVisible: boolean;
    handleOk: () => void;
    handleCancel: any;
    handleImport: (layoutJson: any) => void;
    handleExport: () => void;
}

/**
 * The Import / Export modal. Opens when clicking the Export / Import button in the header.
 */
function ImportExportModal(props: QueueConfigModalProps) {
    const { t } = useTranslation();

    async function handleOk() {
        await props.handleOk();
    }

    const draggerProps = {
        accept: '.json',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                debuglog('IMPORT text', e.target.result);
                props.handleImport(JSON.parse(e.target.result.toString()));
            };
            reader.readAsText(file);

            // Prevent upload
            return false;
        },
    };

    return (
        <Modal title={t('importexportmodal.title')} visible={props.isVisible} onOk={handleOk} onCancel={props.handleCancel}>
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <DownloadOutlined />
                </p>
                <p className="ant-upload-text">{t('importexportmodal.import.text')}</p>
                <p className="ant-upload-hint">{t('importexportmodal.import.hint')}</p>
            </Dragger>

            <Divider>{t('importexportmodal.divider')}</Divider>

            <Button icon={<UploadOutlined />} type="primary" onClick={props.handleExport} style={{ width: '100%' }}>
                {t('importexportmodal.export')}
            </Button>
        </Modal>
    );
}

export default ImportExportModal;
