'use strict';

import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import { MetricSelectionComponent, QueueSelectionComponent } from '../../components/WidgetSettingsForm';

export default function ChartWidgetSettings(
    t: any,
    availableMetrics: string[],
    availableSlaThresholds: string[],
    selectedMetric: string,
    setSelectedMetric: React.Dispatch<React.SetStateAction<string>>,
    setSelectedQueues: (value: string[]) => void,
    queueList: { name: string; id: string }[],
    selectedQueues: string[],
    cacheUpdating: boolean,
) {
    return (
        <div>
            {MetricSelectionComponent(t, availableMetrics, availableSlaThresholds, selectedMetric, setSelectedMetric)}

            {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues, cacheUpdating)}

            <Form.Item label={t('widgetsettingsform.chartwidget.chartlabel.label')} name="chartlabel">
                <Input />
            </Form.Item>

            <Form.Item label={t('widgetsettingsform.chartwidget.charttimerange.label')} name="charttimerange">
                <InputNumber min={1} max={60} />
            </Form.Item>
        </div>
    );
}
