import * as React from 'react';

import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';

// Localisation:
import { useTranslation } from 'react-i18next';

export enum AuthError {
    NoConnection,
    NoSubscription,
    NoPermission,
    Timeout,
}

interface AuthErrorResult {
    title: string;
    subTitle: string;
    status?: ResultStatusType;
}

interface AuthorizationErrorProps {
    authError: AuthError;
    timedOut: boolean;
    resetConnection: () => {};
}

const marketplaceURL = 'https://aws.amazon.com/marketplace/pp/prodview-u3w6iezav5is2';

/**
 * A component used to show any errors that occur during authorisation.
 */
function AuthorizationError(props: AuthorizationErrorProps) {
    const { t } = useTranslation();

    const [authErrorResult, setAuthErrorResult] = React.useState<AuthErrorResult>({
        title: '',
        subTitle: '',
    });

    React.useEffect(() => {
        if (props.authError == null) return;

        switch (props.authError) {
            case AuthError.NoConnection:
                setAuthErrorResult({
                    title: t('authorizationerror.errors.noconnection.title'),
                    subTitle: '',
                });
                break;
            case AuthError.NoSubscription:
                setAuthErrorResult({
                    title: t('authorizationerror.errors.nosubscription.title'),
                    subTitle: t('authorizationerror.errors.nosubscription.subtitle'),
                });
                break;
            case AuthError.NoPermission:
                setAuthErrorResult({
                    title: t('authorizationerror.errors.nopermission.title'),
                    subTitle: t('authorizationerror.errors.nopermission.subtitle'),
                });
                break;
            case AuthError.Timeout:
                if (props.timedOut) {
                    setAuthErrorResult({
                        title: t('authorizationerror.errors.timeout.title'),
                        subTitle: t('authorizationerror.errors.timeout.subtitle'),
                        status: 'info',
                    });
                } else {
                    setAuthErrorResult({
                        title: t('authorizationerror.errors.inactive.title'),
                        subTitle: t('authorizationerror.errors.inactive.subtitle'),
                        status: 'info',
                    });
                }
                break;
        }

        return;
    }, [props.authError]);

    return (
        <Result
            status={authErrorResult.status ? authErrorResult.status : 'warning'}
            title={authErrorResult.title}
            subTitle={authErrorResult.subTitle}
            extra={
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    {authErrorResult.title.includes('subscription') ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '8px',
                            }}
                        >
                            <Button type="primary" href={marketplaceURL}>
                                {t('authorizationerror.gotomarketplace')}
                            </Button>
                            <Button type="default" onClick={props.resetConnection}>
                                {t('authorizationerror.return')}
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: '8px',
                            }}
                        >
                            <Button type="primary" onClick={props.resetConnection}>
                                {t('authorizationerror.return')}
                            </Button>
                        </div>
                    )}
                </div>
            }
        />
    );
}

export default AuthorizationError;
