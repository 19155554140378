'use strict';

import { SoundOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgentModel } from 'src/widgets/agent/AgentWidget';
import { debuglog } from '../scripts/debugLog';
import { getDefaultMetricLabel } from '../scripts/MetricMapping';
import AgentWidgetSettings from '../widgets/agent/AgentWidgetSettings';
import ChartWidgetSettings from '../widgets/chart/ChartWidgetSettings';
import GaugeWidgetSettings from '../widgets/gauge/GaugeWidgetSettings';
import LabelWidgetSettings from '../widgets/label/LabelWidgetSettings';
import MetricWidgetSettings from '../widgets/metric/MetricWidgetSettings';
import { TableWidgetConfig } from '../widgets/table/TableWidget';
import TableWidgetSettings from '../widgets/table/TableWidgetSettings';
import { WidgetConfig, WidgetType } from './WidgetGrid';

const { Option } = Select;

interface WidgetSettingsFormProps {
    widgetType: WidgetType;
    availableMetrics: string[];
    availableSlaThresholds: string[];
    queueList: { name: string; id: string }[];
    currentWidget: WidgetConfig;
    availableAlarms: string[];
    openAlarmModal: () => void;
    availableAgents: AgentModel[];
    cacheUpdating: boolean;
}

/**
 * A common component that contains all the widget settings. Used by the Add Widget and Edit Widget modals.
 */
function WidgetSettingsForm(props: WidgetSettingsFormProps) {
    const { t } = useTranslation();

    const [selectedMetric, setSelectedMetric] = React.useState('');
    const [selectedMetrics, setSelectedMetrics] = React.useState<string[]>([]);
    const [selectedQueues, setSelectedQueues] = React.useState<string[]>([]);
    const [selectedAgents, setSelectedAgents] = React.useState<string[]>([]);

    // Refresh form if widget has changed:
    React.useEffect(() => {
        async function resetForm() {
            await setSelectedMetric(props.currentWidget.config.metricid);
            await setSelectedQueues(props.currentWidget.config.queues);
            debuglog('Selected queues', selectedQueues);

            if (props.currentWidget.type === WidgetType.Table) {
                await setSelectedMetrics((props.currentWidget.config as TableWidgetConfig).selectedMetrics);
            }
        }

        if (props.currentWidget) resetForm();
        return;
    }, [props.currentWidget]);

    React.useEffect(() => {
        debuglog('Selected queues', selectedQueues);
        return;
    }, [selectedQueues]);

    return (
        <div>
            {props.widgetType === WidgetType.Label && LabelWidgetSettings(t)}

            {props.widgetType === WidgetType.Metric &&
                MetricWidgetSettings(
                    t,
                    props.availableMetrics,
                    props.availableSlaThresholds,
                    selectedMetric,
                    setSelectedMetric,
                    setSelectedQueues,
                    props.queueList,
                    selectedQueues,
                    props.availableAlarms,
                    props.openAlarmModal,
                    props.cacheUpdating,
                )}

            {props.widgetType === WidgetType.Chart &&
                ChartWidgetSettings(
                    t,
                    props.availableMetrics,
                    props.availableSlaThresholds,
                    selectedMetric,
                    setSelectedMetric,
                    setSelectedQueues,
                    props.queueList,
                    selectedQueues,
                    props.cacheUpdating,
                )}

            {props.widgetType === WidgetType.Table &&
                TableWidgetSettings(
                    t,
                    props.availableMetrics,
                    props.availableSlaThresholds,
                    selectedMetrics,
                    setSelectedMetrics,
                    setSelectedQueues,
                    props.queueList,
                    selectedQueues,
                    props.cacheUpdating,
                )}

            {props.widgetType === WidgetType.Gauge &&
                GaugeWidgetSettings(
                    t,
                    props.availableMetrics,
                    props.availableSlaThresholds,
                    selectedMetric,
                    setSelectedMetric,
                    setSelectedQueues,
                    props.queueList,
                    selectedQueues,
                    props.availableAlarms,
                    props.openAlarmModal,
                    props.cacheUpdating,
                )}

            {props.widgetType === WidgetType.Agent && AgentWidgetSettings(t, props.availableAgents, setSelectedAgents, selectedAgents, props.cacheUpdating)}
        </div>
    );
}

/**
 * A common setting component to select a single metric.
 */
export function MetricSelectionComponent(
    t: any,
    availableMetrics: string[],
    availableSlaThresholds: string[],
    selectedMetric: string,
    setSelectedMetric: React.Dispatch<React.SetStateAction<string>>,
) {
    return (
        <div>
            <Form.Item
                label={t('widgetsettingsform.metricselection.metric.label')}
                name="metricid"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.metricselection.metric.required'),
                    },
                ]}
            >
                <Select
                    placeholder={t('widgetsettingsform.metricselection.metric.placeholder')}
                    onFocus={null}
                    onChange={(selectValue: string) => {
                        setSelectedMetric(selectValue);
                    }}
                >
                    {availableMetrics.map((key) => {
                        return (
                            <Option key={key} value={key}>
                                {getDefaultMetricLabel(t, key)}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>

            {selectedMetric == 'SERVICE_LEVEL' && (
                <Form.Item
                    label={t('widgetsettingsform.metricselection.servicelevel.label')}
                    name="slaThreshold"
                    rules={[
                        {
                            required: true,
                            message: t('widgetsettingsform.metricselection.servicelevel.required'),
                        },
                    ]}
                >
                    <Select placeholder={t('widgetsettingsform.metricselection.servicelevel.placeholder')} onFocus={null}>
                        {availableSlaThresholds.map((key) => {
                            return (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            )}
        </div>
    );
}

/**
 * A common setting component to select one or more metrics.
 */
export function MultipleMetricSelectionComponent(t: any, availableMetrics: string[], availableSlaThresholds: string[], selectedMetrics: string[], setSelectedMetrics: (value: string[]) => void) {
    return (
        <div>
            <Form.Item
                label={t('widgetsettingsform.multiplemetricselection.label')}
                name="metricids"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.multiplemetricselection.required'),
                    },
                ]}
            >
                <Select
                    placeholder={t('widgetsettingsform.multiplemetricselection.placeholder')}
                    mode="multiple"
                    allowClear
                    value={selectedMetrics}
                    onChange={setSelectedMetrics}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {availableMetrics.map((key) => {
                        return (
                            <Option key={key} value={key}>
                                {getDefaultMetricLabel(t, key)}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>

            {selectedMetrics.find((s) => s === 'SERVICE_LEVEL') && (
                <Form.Item
                    label={t('widgetsettingsform.metricselection.servicelevel.label')}
                    name="slaThreshold"
                    rules={[
                        {
                            required: true,
                            message: t('widgetsettingsform.metricselection.servicelevel.required'),
                        },
                    ]}
                >
                    <Select placeholder={t('widgetsettingsform.metricselection.servicelevel.placeholder')} onFocus={null}>
                        {availableSlaThresholds.map((key) => {
                            return (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            )}
        </div>
    );
}

/**
 * A common setting component to select one or more queues.
 */
export function QueueSelectionComponent(t: any, queueList: { name: string; id: string }[], selectedQueues: string[], setSelectedQueues: (value: string[]) => void, cacheUpdating: boolean) {
    return (
        <div>
            <Form.Item
                label={t('widgetsettingsform.queueselection.label')}
                name="queues"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.queueselection.required'),
                    },
                ]}
            >
                <Select
                    placeholder={t('widgetsettingsform.queueselection.placeholder')}
                    mode="multiple"
                    allowClear
                    value={selectedQueues}
                    onChange={setSelectedQueues}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {queueList.map((q) => (
                        <Select.Option key={q.id} value={q.id}>
                            {q.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {cacheUpdating && (
                <Form.Item>
                    <Alert message={t('widgetsettingsform.cachesync.queuewarning')} type="warning" showIcon />
                </Form.Item>
            )}
        </div>
    );
}

/**
 * A common setting component to select threshold settings (amber / red thresholds, blinking, alarming).
 */
export function ThresholdComponent(props: { t: any; showAlarms?: boolean; availableAlarms?: string[]; openAlarmModal?: () => void }) {
    return (
        <div>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricamber.label')} name="metricamber" tooltip={props.t('widgetsettingsform.metricwidget.metricamber.tooltip')}>
                        <InputNumber />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricred.label')} name="metricred" tooltip={props.t('widgetsettingsform.metricwidget.metricred.tooltip')}>
                        <InputNumber />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricblink.label')} name="metricblink" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            {props.showAlarms && (
                <>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label={props.t('widgetsettingsform.metricwidget.metricambersound.label')} name="metricambersound">
                                <Select defaultValue="">
                                    <Option value="">None</Option>
                                    {props.availableAlarms.map((n, i) => {
                                        return (
                                            <Option key={i} value={n}>
                                                {n}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={props.t('widgetsettingsform.metricwidget.metricredsound.label')} name="metricredsound">
                                <Select defaultValue="">
                                    <Option value="">None</Option>
                                    {props.availableAlarms.map((n, i) => {
                                        return (
                                            <Option key={i} value={n}>
                                                {n}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button onClick={props.openAlarmModal} icon={<SoundOutlined />}>
                                    {props.t('header.managealarms')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

/**
 * A common setting component to select time thresholds
 */
export function TimeThresholdComponent(props: { t: any; showAlarms?: boolean; availableAlarms?: string[]; openAlarmModal?: () => void }) {
    function formatMillis(value: number) {
        const padded = `${value}`.padStart(6, '0');
        return padded.replace(/\B(?=(\d{2}){1,2}(?!\d))/g, ':');
    }

    return (
        <div>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricamber.label')} name="metricamber" tooltip={props.t('widgetsettingsform.metricwidget.metricamber.tooltip')}>
                        <InputNumber style={{ width: '60%' }} formatter={formatMillis} min={0} max={999999} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricred.label')} name="metricred" tooltip={props.t('widgetsettingsform.metricwidget.metricred.tooltip')}>
                        <InputNumber style={{ width: '60%' }} formatter={formatMillis} min={0} max={999999} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={props.t('widgetsettingsform.metricwidget.metricblink.label')} name="metricblink" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            {props.showAlarms && (
                <>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label={props.t('widgetsettingsform.metricwidget.metricambersound.label')} name="metricambersound">
                                <Select defaultValue="">
                                    <Option value="">None</Option>
                                    {props.availableAlarms.map((n, i) => {
                                        return (
                                            <Option key={i} value={n}>
                                                {n}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={props.t('widgetsettingsform.metricwidget.metricredsound.label')} name="metricredsound">
                                <Select defaultValue="">
                                    <Option value="">None</Option>
                                    {props.availableAlarms.map((n, i) => {
                                        return (
                                            <Option key={i} value={n}>
                                                {n}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button onClick={props.openAlarmModal} icon={<SoundOutlined />}>
                                    {props.t('header.managealarms')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default WidgetSettingsForm;
