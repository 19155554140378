/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import GridLayout from 'react-grid-layout';
import localforage from 'localforage';
import * as SemVer from 'compare-versions';

// Styles:
import { changeAntdTheme } from './antd-theme-switcher/index';

// Components:
import Header from './components/Header';
import AuthorizationError, { AuthError } from './components/AuthorizationError';
import InitConnectForm from './components/InitConnectForm';
import SettingsModal from './modals/SettingsModal';
import EditWidgetModal from './modals/EditWidgetModal';
import AddWidgetModal from './modals/AddWidgetModal';
import ModifyThemeModal, { ThemeColors } from './modals/ModifyThemeModal';
import ImportExportModal from './modals/ImportExportModal';
import HelpModal from './modals/HelpModal';
import ManageAlarmsModal from './modals/ManageAlarmsModal';
import * as SplashModal from './modals/SplashModal';

import { Metric, WidgetType, WidgetConfig, AgentMetric, AgentContact } from './components/WidgetGrid';
import { MetricWidgetConfig } from './widgets/metric/MetricWidget';
import WidgetGrid from './components/WidgetGrid';
import { Alarmer, AlarmSound, SerializedAlarmSound } from './components/Alarmer';
import { AgentModel, AgentWidgetConfig } from './widgets/agent/AgentWidget';
import Branding from './components/Branding';

// Layout:
import { Layout, message, notification } from 'antd';
const { Content } = Layout;

// Localisation:
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import ConfigProvider from 'antd/es/config-provider';

// Available languages
import locale_enUS from 'antd/lib/locale/en_US';
import locale_enGB from 'antd/lib/locale/en_GB';
import locale_deDE from 'antd/lib/locale/de_DE';

// Scripts:
import * as socketCalls from './scripts/socketCalls';
import useInterval from './scripts/useInterval';

import { debuglog } from './scripts/debugLog';

import { version as FRONTEND_VERSION } from '../package.json';
import { AgentData } from './types/EmitDataTypes';

const word = 'wallboard'; //REQUIRED agent needs to have a queue containing this word (case insensitive) in their routing profile to access this product

const subscriptionPollDelay = 1000 * 60 * 5; // Time between each subscription check

console.log(`Welcome to Real Time Dashboard v${FRONTEND_VERSION}!`);

const App = (): any => {
    const { t, i18n } = useTranslation();

    /**
     * Init Connect
     *
     * Initializes the Wallboard's connection to the billing API
     *
     * 1. Grab agent config from Amazon Connect agent stream
     * 2. Open WebSocket with API uri
     * 3. Check subscription status using agent config's AWS Account ID
     * 4. Update API with connection using agent config's Connect Instance ID
     */

    enum InitState {
        Unauthorized, // Has not yet passed initConnect (authorisation with Amazon Connect)
        Initialising, // Attempting to validate subscription / agent / version
        Caching, // Check if the cache is updating, if so wait until its finished
        Skinning, // Initialises all customisations (theming, layout, widget configs)
        QueueList, // Initialises list of all available queues and iterates widget configs for queues in use
        QueueData, // Get queue data
        Complete, // Initialisation complete
    }

    const [initState, setInitState] = useState<InitState>(InitState.Unauthorized); // The current state of initialisation (used to sequentially run tasks)

    const [authorizationError, setAuthorizationError] = useState<AuthError>(null); // Used when there is a connection error
    const [connectInstanceARN, setConnectInstanceARN] = useState(''); // REQUIRED popoulated by agent-streams
    const [agentUsername, setAgentUsername] = useState(''); // Used to save skinning per agent
    const [accountID, setAccountID] = useState(''); // Used to check subscription status
    const [authorized, setAuthorized] = useState(false); // Used to check subscription status
    const [connected, setConnected] = useState(false); // Whether the websocket is connected or not

    /**
     * Initialises a connecion with the wallboard web socket.
     * @param instanceURL The Amazon Connect instance URL provided by the user.
     * @param ssoURL The Amazon Connect instance SSO URL provided by the user.
     */
    async function initConnect(instanceURL: string, ssoURL: string): Promise<void> {
        await setInitState(InitState.Initialising);

        if (!instanceURL.endsWith('/')) instanceURL += '/';

        debuglog('URL Values:', instanceURL, ssoURL);

        if (global.localStorage) {
            global.localStorage.removeItem('connectPopupManager::connect::loginPopup');
        }
        const agentConfig = await socketCalls.getAgentConfig(instanceURL, ssoURL).catch(async (error: any) => {
            debuglog('Start Wallboard Error', error);
            await setAuthorizationError(AuthError.NoConnection);
        });

        debuglog('Agent Config Result', agentConfig);

        const connectInstanceARN = agentConfig.agentStates[0].agentStateARN.split('/')[0] + '/' + agentConfig.agentStates[0].agentStateARN.split('/')[1];
        await setConnectInstanceARN(connectInstanceARN);

        const accountId = agentConfig.agentStates[0].agentStateARN.split(':')[4];
        await setAccountID(accountId);

        await setAgentUsername(agentConfig.username);

        const wallboardAgentEnabled = agentConfig.routingProfile.queues.findIndex((element) => {
            debuglog(element);
            const result = !element.name ? false : element.name.toLowerCase().includes(word); // Agent personal Queues are null in the name field!!
            return result;
        });

        // 1. If agent is not a member of a queue containing the word "[Ww]allboard", show authorization exception:
        if (wallboardAgentEnabled < 0) {
            await setAuthorizationError(AuthError.NoPermission);
            await setInitState(InitState.Unauthorized);
            return;
        }

        await socketCalls.openSocket(connectInstanceARN, onWebsocketConnect, onWebsocketDisconnect, cacheUpdatingCallback, cacheUpdatedCallback);

        // 2. Check subscription:
        await checkSubscription(connectInstanceARN, true);

        // 3. Check version drift:
        const versionRes = await socketCalls.emitData('getClientVersion', null, connectInstanceARN);
        const clientVer = versionRes?.ClientVersion as string;
        const FrontendVer = FRONTEND_VERSION;
        debuglog(`Checking version - FRONTEND: ${FrontendVer} vs CLIENT: ${clientVer}`);

        if (checkClientVersion(clientVer)) {
            // All checks passed!
            debuglog('All checks passed!');
            setAuthorized(true);

            // Start wallboard cache check:
            setInitState(InitState.Caching);
        }
    }

    const connectionMsgKey = 'connection_msg';
    const [showMessage, setShowMessage] = useState<string>('');

    // Use useEffect here, since we cannot read 'timedOut' within the websocket callbacks:
    React.useEffect((): void => {
        debuglog('showMessage', showMessage);

        switch (showMessage) {
            case 'connected':
                message.success({ key: connectionMsgKey, content: t('connection.connected.message') });
                break;

            case 'disconnected':
                if (!timedOut) {
                    message.loading({
                        key: connectionMsgKey,
                        content: t('connection.disconnected.message'),
                        duration: 0,
                    });
                } else {
                    message.destroy(connectionMsgKey);
                }
                break;

            case '':
                message.destroy(connectionMsgKey);
                break;
        }
    }, [showMessage]);

    /**
     * Websocket callback when connected.
     */
    async function onWebsocketConnect(): Promise<void> {
        await setConnected(true);
        await setShowMessage('connected');
    }

    /**
     * Websocket callback when disconnected.
     */
    async function onWebsocketDisconnect(): Promise<void> {
        await setConnected(false);
        await setShowMessage('disconnected');
    }

    let subCheckAttempts = 0;
    const [subscribed, setSubscribed] = useState<boolean>();

    /**
     * Effect when subscribed status is changed.
     */
    React.useEffect((): void => {
        if (subscribed === undefined) return;

        debuglog('Subscribed: ', subscribed);

        if (!subscribed) {
            resetQueueData();
            setAuthorizationError(AuthError.NoSubscription);
            setInitState(InitState.Unauthorized);
            setShowMessage('');
            socketCalls.closeSocket();
        }

        subCheckAttempts = 0;
    }, [subscribed]);

    /**
     * Checks the Subscription state to determine if the product is allowed to run
     * @param instanceARN The connect instance to check subscription status for.
     * @param init `true` we are running the check within the initialisation phase (initConnect).
     */
    async function checkSubscription(instanceARN: string, init: boolean): Promise<void> {
        // Check the Subscription state to determine if the product is allowed to run
        try {
            subCheckAttempts++;
            debuglog(`Checking subscription. Attempt #${subCheckAttempts}`);

            if (subCheckAttempts <= 3) {
                const res = await socketCalls.emitData('checkSubscription', null, instanceARN);
                await setSubscribed(res.result === 'Subscribed');
            } else {
                // Assume we are not subscribed after 3 retries
                await setSubscribed(false);
            }
        } catch (err) {
            debuglog('Subscribed error: ', err);
            return; // Assume we are still subscribed and try again
        }

        // Check version drift to notify user to refresh:
        if (!init) {
            const versionRes = await socketCalls.emitData('checkVersions', null, connectInstanceARN);
            const productVer: string = versionRes?.ProductVersion;
            debuglog(`Checking version - FRONTEND: ${FRONTEND_VERSION} < PRODUCT: ${productVer}`);

            checkServerVersion(productVer);
        }
    }

    /**
     * Shows a message if the front-end version is lower than the back-end version
     * @param Version The version of the backend
     */
    function checkServerVersion(Version: string): boolean {
        if (Version) {
            if (SemVer.satisfies(FRONTEND_VERSION, '<' + Version)) {
                notification.destroy();
                notification.info({
                    message: t('versionnotification.message'),
                    description: t('versionnotification.description'),
                    placement: 'bottomLeft',
                    duration: 0,
                });
            }
        } else {
            // TODO HANDLE VERSION NOT SET ERRORS
            // FOR THE MOMENT BLINDLY BLITHER ON
        }
        return true;
    }

    /**
     * Checks whether the client version is older than the front end version. Kicks the user out if the client version is not up to date.
     * @param Version The version of the client
     */
    function checkClientVersion(Version: string): boolean {
        // if clientVer Exists
        if (Version) {
            const FrontendVerArray = FRONTEND_VERSION.split('.');
            const minorFrontendVer = FrontendVerArray[0] + '.' + FrontendVerArray[1] + '.0';
            debuglog(`SET: FRONTEND: ${minorFrontendVer}, CLIENT: ${Version}`);
            // Test for patch version okness, then invert to say its NOT ok.  semver tests for includivity.
            if (!SemVer.satisfies(Version, '~' + minorFrontendVer)) {
                debuglog(`FRONTEND MINOR FAIL: FRONTEND: ${minorFrontendVer}, CLIENT: ${Version}`);
                setSplashVisible(true);
                setInitState(InitState.Unauthorized);
                return false;
            }

            //Check for Patch drift, and recommend updating, but allow continue
            if (!SemVer.satisfies(Version, '~' + FRONTEND_VERSION)) {
                debuglog(`FRONTEND PATCH FAIL: FRONTEND: ${FRONTEND_VERSION}, CLIENT: ${Version}`);
                setSplashPatchVisible(true);
                return true;
            }
            return true;
        } else {
            // TODO HANDLE VERSION NOT SET ERRORS
            // FOR THE MOMENT BLINDLY BLITHER ON
            return true;
        }
    }

    // Initialise a polling timer for subscription, that triggers only if we have passed authorization
    useInterval(
        async (): Promise<void> => {
            await checkSubscription(connectInstanceARN, false);
        },
        authorized ? subscriptionPollDelay : null,
    );

    /**
     * Cleans the current queue data.
     */
    async function resetQueueData(): Promise<void> {
        await setCurrentQueueData(null);
        await setCurrentQueues([]);
        await setAuthorized(false);
    }

    /**
     * Called when the connection is reset to clean relevant variables.
     */
    async function resetConnection(): Promise<void> {
        setAuthorizationError(null);
        setConnectInstanceARN('');
        setAccountID('');
        setAuthorized(false);
        setInitState(InitState.Unauthorized);
    }

    /**
     * Caching checks
     */
    const [cacheUpdating, setCacheUpdating] = useState<boolean>(false);
    const [cacheUpdateCompleted, setCacheUpdateCompleted] = useState<boolean>(false);

    /**
     * Check whether the cache is alive.
     */
    async function checkConnectCache(): Promise<void> {
        await socketCalls.emitData('checkCacheStatus', null, connectInstanceARN);
    }

    /**
     * Start client-side cache updates.
     */
    async function updateConnectCache(): Promise<void> {
        await socketCalls.emitData('refreshCache', null, connectInstanceARN);
        setCacheUpdating(true);
    }

    /**
     * Websocket callback when cache begins updating.
     */
    async function cacheUpdatingCallback(): Promise<void> {
        setCacheUpdating(true);
    }

    /**
     * Websocket callback when cache finishes updating.
     */
    async function cacheUpdatedCallback(): Promise<void> {
        setCacheUpdating(false);
    }

    // Initialise wallboard if authorization passes:
    React.useEffect((): void => {
        // TODO Check database state to see if it is updating.  If yes PAUSE until its finished then run.

        async function checkCacheStatus(): Promise<void> {
            await checkConnectCache();
            setInitState(InitState.Skinning);
        }

        async function initSkinning(): Promise<void> {
            await getSkinning();
            setInitState(InitState.QueueList);
        }
        async function initQueueList(): Promise<void> {
            await updateQueueList();
            await updateAgentList();
            await recalculatePollTargets();
            setInitState(InitState.QueueData);
        }
        async function initQueueData(): Promise<void> {
            await pollQueueData();
            await pollAgentData();
            setInitState(InitState.Complete);
        }

        if (!authorized) return;

        switch (initState) {
            case InitState.Caching:
                checkCacheStatus();
                break;

            case InitState.Skinning:
                initSkinning();
                break;

            case InitState.QueueList:
                initQueueList();
                break;

            case InitState.QueueData:
                initQueueData();
                break;

            default:
                return;
        }
    }, [initState]);

    /**
     * Queue Metrics
     */

    const [currentQueues, setCurrentQueues] = useState<string[][]>([]);
    const [queueList, setQueueList] = useState<{ name: string; id: string }[]>([]);
    const [availableMetrics, setAvailableMetrics] = useState<string[]>([]);
    const [availableSlaThresholds, setAvailableSlaThresholds] = useState<string[]>([]);

    // Holds all the polled queue data. The key is the array of queues as a string: widgetQueues.toString()
    const [currentQueueData, setCurrentQueueData] = useState<{ [key: string]: Metric[] }>();

    const pollTime = 5000;

    // Initialise a polling timer for queueDatas
    useInterval(
        async (): Promise<void> => {
            if (connected) {
                if (currentQueues.length > 0) {
                    await pollQueueData();
                    await pollAgentData();
                }
            } else {
                // If websocket is closed, try to reopen:
                await socketCalls.openSocket(connectInstanceARN, onWebsocketConnect, onWebsocketDisconnect, cacheUpdatedCallback);
            }
        },
        authorized ? pollTime : null,
    );

    /**
     * Called when an initial queue is selected from the start screen.
     * @param queue The selected queue.
     */
    async function setInitialQueue(queue: string): Promise<void> {
        await widgetConfigs.forEach((config) => {
            config.config.queues = [queue];
        });

        await setInitState(InitState.QueueList);

        debuglog(`Initial queue selected ${queue}`, widgetConfigs);

        await storeSkinning({
            layout: defaultLayout,
            widgetConfigs: widgetConfigs,
        });
    }

    /**
     * Updates the queue list with the latest data from the websocket.
     */
    async function updateQueueList(): Promise<void> {
        await setQueueList([]);
        const queueResponse = await socketCalls.emitData('getQueueList', null, connectInstanceARN);
        const queues = queueResponse.Items;
        const newQueueList = [];
        for (const queue in queues) {
            if (queues[queue].Name.toLowerCase().includes(word)) continue;

            newQueueList.push({
                name: queues[queue].Name,
                id: (queues[queue].Arn as string).split('/')[3],
            });
        }

        // Sort alphabetically
        newQueueList.sort((a: { name: string; id: string }, b: { name: string; id: string }) => {
            return a.name.localeCompare(b.name);
        });

        debuglog('React (App.tsx): QueueList updated - ', newQueueList);

        await setQueueList(newQueueList);
    }

    /**
     * Polls metrics for the currently selected queues through the websocket.
     */
    async function pollQueueData(): Promise<void> {
        if (!currentQueues || currentQueues.length == 0) return;

        const newQueueDataList = {};
        const newAvailableMetrics = [];
        const newAvailableSlaThresholds = [];

        debuglog('Polling queues: ', currentQueues);

        for (const queues of currentQueues) {
            debuglog('React (App.tsx): Get queue data - ' + queues);
            const queueData = await socketCalls.emitData('getQueueData', { queueList: queues }, connectInstanceARN);

            debuglog('Polled data', queueData);

            const newQueueData: Metric[] = [];
            const queueCollection = queueData.Items[0].Data[0].Collections;

            // Add each metric to new list:
            for (const metric in queueCollection) {
                const name = queueCollection[metric].Metric.Name;
                const threshold = queueCollection[metric].Metric.Threshold?.ThresholdValue;

                newQueueData.push({
                    name: name,
                    value: queueCollection[metric].Value,
                    unit: queueCollection[metric].Metric.Unit,
                    statistic: queueCollection[metric].Metric.Statistic,
                    threshold: threshold,
                });

                if (threshold && !newAvailableSlaThresholds.find((th) => th == threshold)) newAvailableSlaThresholds.push(threshold);

                if (!newAvailableMetrics.find((m) => m == name)) newAvailableMetrics.push(name);
            }

            debuglog('React (App.tsx): Get queue data result', newQueueData);

            newQueueDataList[queues.toString()] = newQueueData;
        }
        debuglog('React (App.tsx): Poll complete', newAvailableMetrics, newQueueDataList);

        await setAvailableMetrics(newAvailableMetrics.sort());
        await setAvailableSlaThresholds(newAvailableSlaThresholds);
        await setCurrentQueueData(newQueueDataList);
    }

    /**
     * Agent Metrics
     */

    const [currentAgents, setCurrentAgents] = useState<string[]>([]); // Selected agents
    const [agentList, setAgentList] = useState<AgentModel[]>([]); // Available agents
    const [currentAgentData, setCurrentAgentData] = useState<{ [key: string]: AgentMetric }>(); // Data for selected agents

    /**
     * Gets the latest agent list from the websocket.
     */
    async function updateAgentList(): Promise<void> {
        await setAgentList([]);
        const newAgentList: AgentModel[] = [];

        const ase = await socketCalls.emitData('enableAgentStatusTable', null, connectInstanceARN);
        debuglog('Agent Status Enabled:', ase.message);

        const al = await socketCalls.emitData('getAgentList', null, connectInstanceARN);
        debuglog('Agent List:', al);

        for (const agent of al.Items) {
            const newAgent: AgentModel = {
                id: agent.Id,
                username: agent.Username,
            };

            if (agent.IdentityInfo) {
                newAgent.identity = {
                    name: `${agent.IdentityInfo.FirstName} ${agent.IdentityInfo.LastName}`,
                    email: agent.IdentityInfo.Email,
                };
            }

            newAgentList.push(newAgent);
        }

        debuglog('New Agent List:', newAgentList);

        await setAgentList(newAgentList);
    }

    /**
     * Polls agent metrics for the currently selected agents through the websocket.
     */
    async function pollAgentData(): Promise<void> {
        if (!currentAgents || currentAgents.length == 0) return;

        const newAgentData: { [key: string]: AgentMetric } = {};

        // Get agent data for midnight to now:
        const now = new Date();
        const midnightLocal = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

        debuglog(`Polling agents since ${midnightLocal} (${midnightLocal.getTime()}): `, currentAgents);

        for (const agent of currentAgents) {
            const agentData: AgentData = await socketCalls.emitData('getAgentData', { agentId: agent, windowStartTime: midnightLocal.getTime() }, connectInstanceARN);
            const agentInfo = agentList.find((v) => v.id === agent);
            debuglog(`Polled agent data for ${agent}`, agentData, agentInfo);
            if (!agentInfo) continue;

            // Check previous agent for status change
            //const agentStatus = agentData.Status[0];
            const agentStatus = !agentData.Status ? undefined : agentData.Status[0];
            let eventTimestamp;

            if (agentStatus) {
                eventTimestamp = Date.parse(agentStatus.EventTimestamp);
                if (currentAgentData) {
                    const prevAgentMetric = currentAgentData[agent];
                    if (prevAgentMetric && prevAgentMetric.status === agentStatus.Status) {
                        eventTimestamp = prevAgentMetric.timestamp;
                    }
                }
            }

            const agentToAdd: AgentMetric = {
                id: agentInfo.id,
                username: agentInfo.username,
                name: agentInfo.identity?.name ?? '',
                email: agentInfo.identity?.email ?? '',
                status: agentStatus?.Status ?? 'Offline',
                timestamp: eventTimestamp ?? undefined,
                contacts: [],
                handled: agentData.Handled,
            };

            if (agentStatus?.Contacts && agentStatus.Contacts.length > 0) {
                const cts: AgentContact[] = [];
                agentStatus.Contacts.forEach((ct) => {
                    cts.push({
                        contactId: ct.ContactId,
                        state: ct.State,
                        channel: ct.Channel,
                        queueName: ct.Queue.Name,
                    });
                });
                agentToAdd.contacts = cts;
            }

            newAgentData[agent] = agentToAdd;
        }

        debuglog('React (App.tsx): Agent poll complete', newAgentData);
        await setCurrentAgentData(newAgentData);
    }

    /**
     * Recalculate Poll Targets
     */

    // Loop through widgets to find which queues / agents are selected for polling
    async function recalculatePollTargets(): Promise<void> {
        debuglog('Iterating Configs', widgetConfigs);

        const newQueues: string[][] = [];
        const newAgents: string[] = [];

        // Loop through each widget and grab current queues:
        for (const widget in widgetConfigs) {
            const config = widgetConfigs[widget];
            if (!config) continue;

            debuglog('Queue recalculation...', config);

            if (config.type === WidgetType.Table) {
                // Add all queues individually for Table widgets (rather than getting aggregate):
                const queues = config.config.queues;
                if (!queues || queues.length == 0) continue;

                queues.forEach((q) => {
                    if (
                        !newQueues.find((ql) => {
                            return ql.sort().toString() == [q].sort().toString();
                        })
                    ) {
                        // Only add the queues that haven't been added already
                        newQueues.push([q]);
                    }
                });
            } else if (config.type === WidgetType.Agent) {
                // Only look for agents in agent widgets (ie. ignore queues):
                const agents = (config.config as AgentWidgetConfig).selectedAgents;
                if (!agents || agents.length == 0) continue;

                agents.forEach((a) => {
                    if (
                        !newAgents.find((na) => {
                            return na === a;
                        })
                    ) {
                        // Only add the agents that haven't been added already
                        newAgents.push(a);
                    }
                });
            } else {
                // Add queues as consolidated for all other widgets:
                const queues = config.config.queues;
                if (!queues || queues.length == 0) continue;

                if (
                    !newQueues.find((ql) => {
                        return ql.sort().toString() == queues.sort().toString();
                    })
                ) {
                    // Only add the queues that haven't been added already
                    newQueues.push(queues.sort());
                }
            }
        }

        debuglog('Recalculated queues', newQueues);
        debuglog('Recalculated agents', newAgents);

        await setCurrentQueues(newQueues);
        await setCurrentAgents(newAgents);
    }

    /**
     * Skinning Store
     */

    /**
     * Saves all layout, widgets and stylisation configurations into the client-side data store, for the current user.
     */
    async function storeSkinning(data: any): Promise<void> {
        const res = await socketCalls.emitData(
            'storeSkinning',
            {
                username: agentUsername,
                ...data,
            },
            connectInstanceARN,
        );

        debuglog('React (App.tsx): Stored skinning', res);
    }

    /**
     * Retrieves all layout, widgets and stylisation configurations for the current user.
     */
    async function getSkinning(): Promise<void> {
        const res = await socketCalls.emitData('getSkinning', { username: agentUsername }, connectInstanceARN);

        debuglog('React (App.tsx): Retrieved skinning', res);

        await applySkinning(res);
    }

    /**
     * Parses and applies the skinning layout file so they are displayed in the app. Also used when importing layout json files.
     * @param layoutJson The JSON retrieved from the client-side data store.
     */
    async function applySkinning(layoutJson: any): Promise<void> {
        if (layoutJson.themeColors) {
            await setThemeColors(layoutJson.themeColors);
            if (layoutJson.themeColors.primaryColor) {
                await changeAntdTheme(layoutJson.themeColors.primaryColor, {
                    darkMode: layoutJson.darkMode,
                });
                document.getElementById('header-title').style.color = window.localStorage.getItem('mini-dynamic-antd-theme-color');
            }
        }

        if (layoutJson.widgetConfigs && layoutJson.widgetConfigs.length > 0) {
            // sort layout items so they appear in order
            const newConfigs = await layoutJson.layout
                .sort((a, b) => {
                    // same row, compare X
                    if (a.y === b.y) {
                        return a.x - b.x;
                    }

                    // different row, compare Y
                    return a.y - b.y;
                })
                .map((item) => {
                    const cfg = layoutJson.widgetConfigs.find((wc) => {
                        return wc.widgetid === item.i;
                    });
                    return cfg;
                });

            await setWidgetConfigs(newConfigs);
            await recalculatePollTargets();
        } else {
            await setWidgetConfigs(defaultWidgetConfigs);
            await recalculatePollTargets();
        }

        if (layoutJson.layout) {
            // Remove all minimums:
            for (let w of layoutJson.layout) {
                if (!w) continue;
                if (w.minH) delete w.minH;
                if (w.minW) delete w.minW;
            }
            await setLayout(layoutJson.layout);
        } else {
            await setLayout(defaultLayout);
        }

        if (!layoutJson.timeoutEnabled) {
            await stopWebsocketTimeout();
            await setWebsocketTimeoutDelay(8);
        } else {
            if (layoutJson.timeoutDelay) {
                await startWebsocketTimeout(layoutJson.timeoutDelay);
                await setWebsocketTimeoutDelay(layoutJson.timeoutDelay);
            }
        }

        await setTimedOut(false);

        ToggleDarkMode(layoutJson.darkMode ? layoutJson.darkMode : false);

        if (layoutJson.title) await setTitle(layoutJson.title);

        if (layoutJson.i18n) i18n.changeLanguage(layoutJson.i18n);

        debuglog('React (App.tsx): Applied skinning', layoutJson);
    }

    /**
     * Wallboard Settings
     */

    const [settingsVisible, setSettingsVisible] = useState(false);
    const [websocketTimeoutId, setWebsocketTimeoutId] = useState<number>(); // Websocket timeout ID
    const [websocketTimeoutDelay, setWebsocketTimeoutDelay] = useState<number>(); // Time for websocket to timeout
    const [timedOut, setTimedOut] = useState<boolean>(false);

    const [locale, setLocale] = useState(locale_enUS);

    /**
     * Effect when the language locale is changed.
     */
    React.useEffect((): void => {
        debuglog('Language changed: ', i18n.language);

        switch (i18n.language) {
            case 'en-US':
                setLocale(locale_enUS);
                break;
            case 'en-UK':
                setLocale(locale_enGB);
                break;
            case 'de':
                setLocale(locale_deDE);
                break;
        }
    }, [i18n.language]);

    /**
     * Adds a timeout to the websocket.
     * @param delay The amount of time before timing out in hours.
     */
    async function startWebsocketTimeout(delay: number): Promise<void> {
        // Convert hours to milliseconds:
        const msDelay = delay * 1000 * 60 * 60;

        const timeout = await window.setTimeout(async (): Promise<void> => {
            await setTimedOut(true);
            await resetQueueData();
            await setAuthorizationError(AuthError.Timeout);
            await setShowMessage('');
            socketCalls.closeSocket();
        }, msDelay);

        await setWebsocketTimeoutId(timeout);

        debuglog(`Websocket timeout started (${timeout}) with delay: ${msDelay} (${delay} hours)`);
    }

    /**
     * Disables the timeout on the websocket.
     */
    async function stopWebsocketTimeout(): Promise<void> {
        if (!websocketTimeoutId) return;

        await window.clearTimeout(websocketTimeoutId);
        await setWebsocketTimeoutId(null);

        debuglog('Websocket timeout stopped.');
    }

    /**
     * Handler when the settings modal is submitted.
     * @param timeoutenabled Whether the websocket timeout was enabled.
     * @param timeouttime The websocket timeout delay in hours.
     */
    async function settingsHandleOk(timeoutenabled: boolean, timeouttime: number): Promise<void> {
        if (timeoutenabled) {
            await stopWebsocketTimeout();
            await setWebsocketTimeoutDelay(timeouttime);
            await startWebsocketTimeout(timeouttime);
        } else {
            await stopWebsocketTimeout();
        }

        await setTimedOut(false);

        await setSettingsVisible(false);
        await storeSkinning({
            timeoutEnabled: timeoutenabled,
            timeoutDelay: timeouttime,
            i18n: i18n.language,
        });
    }

    /**
     * Opens the settings modal.
     */
    function onSettingsClicked(): void {
        setSettingsVisible(true);
    }

    /**
     * Effect when client-side cache is finished updating.
     */
    React.useEffect((): void => {
        async function run(): Promise<void> {
            debuglog('Cache update complete. Updating lists...');
            await updateQueueList();
            await updateAgentList();
            await recalculatePollTargets();
            await setCacheUpdateCompleted(false);
        }

        if (connected && cacheUpdateCompleted) run();
    }, [connected, cacheUpdateCompleted]);

    /**
     * Edit Wallboard
     */

    const [title, setTitle] = useState('Amazon Connect Wallboard');
    const [editing, setEditing] = useState(false);

    /**
     * Handler when the 'Edit Wallboard' button is clicked.
     */
    async function onEditClicked(): Promise<void> {
        if (editing) {
            // Loop through each widget and grab current queues / agents:
            await recalculatePollTargets();
            await storeSkinning({
                layout: layout,
                themeColors: themeColors,
                widgetConfigs: widgetConfigs,
                darkMode: darkMode,
                title: title,
            });
        }
        await setEditing(!editing);
    }

    // Widget keys available for queue data metrics:
    const defaultWidgetConfigs: WidgetConfig[] = [
        {
            widgetid: 'CONTACTS_QUEUED',
            type: WidgetType.Metric,
            config: {
                metricid: 'CONTACTS_QUEUED',
                queues: [],
                label: t('widgets.defaults.contactsqueued'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'CONTACTS_HANDLED',
            type: WidgetType.Metric,
            config: {
                metricid: 'CONTACTS_HANDLED',
                queues: [],
                label: t('widgets.defaults.contactshandled'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'CONTACTS_HANDLED_OUTBOUND',
            type: WidgetType.Metric,
            config: {
                metricid: 'CONTACTS_HANDLED_OUTBOUND',
                queues: [],
                label: t('widgets.defaults.contactshandledoutbound'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'SERVICE_LEVEL',
            type: WidgetType.Metric,
            config: {
                metricid: 'SERVICE_LEVEL',
                queues: [],
                label: t('widgets.defaults.servicelevel'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'CONTACTS_ABANDONED',
            type: WidgetType.Metric,
            config: {
                metricid: 'CONTACTS_ABANDONED',
                queues: [],
                label: t('widgets.defaults.contactsabandoned'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'QUEUE_ANSWER_TIME',
            type: WidgetType.Metric,
            config: {
                metricid: 'QUEUE_ANSWER_TIME',
                queues: [],
                label: t('widgets.defaults.queueanswertime'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'CONTACTS_IN_QUEUE',
            type: WidgetType.Metric,
            config: {
                metricid: 'CONTACTS_IN_QUEUE',
                queues: [],
                label: t('widgets.defaults.contactsinqueue'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'OLDEST_CONTACT_AGE',
            type: WidgetType.Metric,
            config: {
                metricid: 'OLDEST_CONTACT_AGE',
                queues: [],
                label: t('widgets.defaults.oldestcontactage'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'AGENTS_ONLINE',
            type: WidgetType.Metric,
            config: {
                metricid: 'AGENTS_ONLINE',
                queues: [],
                label: t('widgets.defaults.agentsonline'),
                amberThreshold: 5,
                redThreshold: 2,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'AGENTS_NON_PRODUCTIVE',
            type: WidgetType.Metric,
            config: {
                metricid: 'AGENTS_NON_PRODUCTIVE',
                queues: [],
                label: t('widgets.defaults.agentsnonproductive'),
                amberThreshold: 2,
                redThreshold: 0,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'AGENTS_AVAILABLE',
            type: WidgetType.Metric,
            config: {
                metricid: 'AGENTS_AVAILABLE',
                queues: [],
                label: t('widgets.defaults.agentsavailable'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
        {
            widgetid: 'HANDLE_TIME',
            type: WidgetType.Metric,
            config: {
                metricid: 'HANDLE_TIME',
                queues: [],
                label: t('widgets.defaults.handletime'),
                amberThreshold: 20,
                redThreshold: 40,
                amberSound: '',
                redSound: '',
                blink: false,
                sla: null,
            } as MetricWidgetConfig,
        },
    ];

    const [widgetConfigs, setWidgetConfigs] = useState<WidgetConfig[]>([]);

    /**
     * Default layout config
     */
    const defaultLayout: GridLayout.Layout[] = [
        { i: 'CONTACTS_QUEUED', x: 0, y: 0, w: 2, h: 2 },
        { i: 'CONTACTS_HANDLED', x: 2, y: 0, w: 2, h: 2 },
        { i: 'CONTACTS_HANDLED_OUTBOUND', x: 4, y: 0, w: 2, h: 2 },
        { i: 'SERVICE_LEVEL', x: 6, y: 0, w: 2, h: 2 },
        { i: 'CONTACTS_ABANDONED', x: 8, y: 0, w: 2, h: 2 },
        { i: 'QUEUE_ANSWER_TIME', x: 10, y: 0, w: 2, h: 2 },
        { i: 'CONTACTS_IN_QUEUE', x: 0, y: 2, w: 2, h: 2 },
        { i: 'OLDEST_CONTACT_AGE', x: 2, y: 2, w: 2, h: 2 },
        { i: 'AGENTS_ONLINE', x: 4, y: 2, w: 2, h: 2 },
        { i: 'AGENTS_NON_PRODUCTIVE', x: 6, y: 2, w: 2, h: 2 },
        { i: 'AGENTS_AVAILABLE', x: 8, y: 2, w: 2, h: 2 },
        { i: 'HANDLE_TIME', x: 10, y: 2, w: 2, h: 2 },
    ];

    const [layout, setLayout] = useState<GridLayout.Layout[]>(defaultLayout);

    /**
     * Holds the ReactGridLayout layout in memory whenenver it is changed.
     * @param layout The changed layout.
     */
    function onLayoutChange(layout): void {
        setLayout(layout);
        debuglog('React (WidgetGrid.tsx): onLayoutChange: ', layout);
    }

    /**
     * Handler when 'Reset Layout' is clicked.
     */
    async function onResetLayoutClicked(): Promise<void> {
        await setCurrentQueues([]);
        await setWidgetConfigs(defaultWidgetConfigs);
        await setLayout(defaultLayout);
    }

    const [editWidgetVisible, setEditWidgetVisible] = React.useState(false);
    const [currentWidget, setCurrentWidget] = React.useState<WidgetConfig>(null);

    /**
     * Handler when the widget settings gear button is clicked.
     * @param widgetid The widget we are about to edit.
     */
    function openEditWidgetModal(widgetid: string): void {
        const index = widgetConfigs.findIndex((w) => w.widgetid === widgetid);

        setCurrentWidget(widgetConfigs[index]);

        debuglog('React (App.tsx): Editing widget', widgetConfigs[index]);

        setEditWidgetVisible(true);
    }

    /**
     * Handler when the widget settings is submitted.
     * @param config The updated widget configuration.
     */
    async function onEditWidgetSave(config: WidgetConfig): Promise<void> {
        const newConfigs = widgetConfigs;
        const index = widgetConfigs.findIndex((w) => w === currentWidget);
        newConfigs[index] = config;

        debuglog('React (App.tsx): Updating widget', newConfigs[index]);

        if (global.localStorage) {
            global.localStorage.setItem(
                'wallboard-config',
                JSON.stringify({
                    ['config']: newConfigs,
                }),
            );
        }

        await setWidgetConfigs(newConfigs);
        await recalculatePollTargets();

        debuglog('React (App.tsx): Widget configs updated', widgetConfigs);

        // Close modal:
        setEditWidgetVisible(false);
    }

    /**
     * Commence a deletion of a widget.
     * @param widgetid The widget to delete.
     */
    async function deleteWidget(widgetid: string): Promise<void> {
        const newConfigs = widgetConfigs;
        const index = widgetConfigs.findIndex((w) => w.widgetid === widgetid);

        debuglog('React (App.tsx): Deleting widget', newConfigs[index]);
        newConfigs.splice(index, 1);

        debuglog('React (App.tsx): Deleting widget', newConfigs);

        await setWidgetConfigs(newConfigs);
        await recalculatePollTargets();

        // Close modal:
        setEditWidgetVisible(false);
    }

    /**
     * Add Widgets
     */

    const [addWidgetVisible, setAddWidgetVisible] = React.useState(false);

    /**
     * Commence addition of a new widget.
     * @param config The widget configuration of the new widget to create.
     */
    async function addWidget(config: WidgetConfig): Promise<void> {
        const newConfigs = widgetConfigs;

        debuglog('React (App.tsx): Adding item', config);

        newConfigs.push(config);
        await setWidgetConfigs(newConfigs);
        await recalculatePollTargets();

        setAddWidgetVisible(false);
    }

    /**
     * Modify Theme
     */

    const [modifyThemeVisible, setModifyThemeVisible] = useState(false);
    const [themeColors, setThemeColors] = useState<ThemeColors>({
        primaryColor: '#1890ff',
        headerColor: '#ffffff',
        amberColor: '#fa8c16',
        redColor: '#f5222d',
    });
    const [darkMode, setDarkMode] = useState(false);

    /**
     * Saves theme colours to memory.
     * @param themeColors The colours to save.
     */
    async function SaveThemeColors(themeColors: ThemeColors): Promise<void> {
        await setThemeColors(themeColors);
    }

    /**
     * Toggles dark mode
     * @param on `true` to switch to dark mode. `false` to switch to light mode.
     */
    async function ToggleDarkMode(on: boolean): Promise<void> {
        if (!on) {
            document.querySelector('#theme').setAttribute('href', './react/lighttheme.css');
            setDarkMode(false);
        } else {
            document.querySelector('#theme').setAttribute('href', './react/darktheme.css');
            setDarkMode(true);
        }
    }

    /**
     * Import / Export
     */

    const [importExportVisible, setImportExportVisible] = useState(false);

    /**
     * Handler to open the import/export modal
     */
    function onImportExportClicked(): void {
        setImportExportVisible(true);
    }

    /**
     * Builds a json file of the current layout and allows the user to download the file.
     */
    async function exportSkinning(): Promise<void> {
        const jsonStr = JSON.stringify({
            username: agentUsername,
            layout: layout,
            themeColors: themeColors,
            widgetConfigs: widgetConfigs,
            darkMode: darkMode,
            title: title,
            timeoutEnabled: websocketTimeoutId != null,
            timeoutDelay: websocketTimeoutDelay,
        });

        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonStr);

        const exportFileDefaultName = 'layout.json';

        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    /**
     * Imports a layout from a user-provided json file.
     * @param layoutJson The json of the imported layout file.
     */
    async function importSkinning(layoutJson: any): Promise<void> {
        applySkinning(layoutJson);
    }

    /**
     * Help / Support
     */

    const [helpVisible, setHelpVisible] = useState(false);

    /**
     * Handler to open the help modal.
     */
    function onHelpClicked(): void {
        setHelpVisible(true);
    }

    /**
     * Fullscreen
     */

    const [fullscreen, setFullscreen] = useState(false);
    const fsHandle = useFullScreenHandle();

    /**
     * Handler to start fullscreen mode.
     */
    function onFullscreenClicked(): void {
        if (fsHandle.active) {
            fsHandle.exit();
        } else {
            fsHandle.enter();
        }
    }

    /**
     * Updates the fullscreen variable if the user manually opens fullscreen mode (F11)
     */
    document.onfullscreenchange = function (): void {
        setFullscreen(document.fullscreenElement !== null);
    };

    /**
     * Alarming
     */

    const [manageAlarmsVisible, setManageAlarmsVisible] = useState(false);

    const alarmer = useRef<Alarmer>();
    const [alarming, setAlarming] = useState<boolean>(false);
    const [alarmWidgets, setAlarmWidgets] = useState<{ [key: string]: string }>({});

    const [alarmList, setAlarmList] = useState<AlarmSound[]>([]);
    const [availableAlarms, setAvailableAlarms] = useState<string[]>([]);

    React.useEffect((): void => {
        async function run(): Promise<void> {
            alarmer.current = new Alarmer();
            const customAlarms = await localforage.getItem<SerializedAlarmSound[]>('alarms');
            debuglog('Custom Alarms: ', customAlarms);
            await alarmer.current.loadSounds(customAlarms);
            refreshAlarmList();
        }

        run();
    }, []);

    React.useEffect((): void => {
        if (alarming) {
            alarmer.current.startAlarm();
        } else {
            alarmer.current.stopAlarm();
        }
    }, [alarming, alarmer]);

    React.useEffect((): void => {
        async function run(): Promise<void> {
            // Set alarm sounds:
            const newAlarmSounds: string[] = [];
            for (const [, value] of Object.entries(alarmWidgets)) {
                if (newAlarmSounds.includes(value)) continue;
                newAlarmSounds.push(value);
                debuglog('Setting Alarm Sound: ', value);
            }
            alarmer.current.setAlarms(newAlarmSounds);

            if (newAlarmSounds.length > 0) await setAlarming(true);
            else await setAlarming(false);
        }

        run();
    }, [alarmWidgets]);

    useInterval(
        // Try play the next sound every 1.5 seconds
        async (): Promise<void> => {
            alarmer.current.runAlarm();
        },
        alarming ? 1500 : null,
    );

    /**
     * Adds an alarm sound to the global list.
     * @param alarm The alarm sound to add.
     */
    function addAlarm(alarm: AlarmSound): void {
        alarmer.current.addToAudioList(alarm);
        refreshAlarmList();
    }

    /**
     * Deletes an alarm sound from the global list.
     * @param name The alarm name to delete.
     */
    function deleteAlarm(name: string): void {
        alarmer.current.removeFromAudioList(name);
        refreshAlarmList();

        // Remove said alarm from any metric config:
        const newWidgetConfigs = [...widgetConfigs];
        for (const w in newWidgetConfigs) {
            const widget = newWidgetConfigs[w];
            if (widget.type != WidgetType.Metric) continue;
            const mWidget = widget.config as MetricWidgetConfig;
            if (mWidget.amberSound === name) mWidget.amberSound = '';
            if (mWidget.redSound === name) mWidget.redSound = '';
        }
        setWidgetConfigs(newWidgetConfigs);
    }

    /**
     * Refreshes current alarm list.
     */
    function refreshAlarmList(): void {
        const mappedNames = alarmer.current.getAudioList().map((cs) => cs.name);
        debuglog('Refreshing Alarms... Mapped Alarms: ', mappedNames);

        setAvailableAlarms([...mappedNames]);
        setAlarmList([...alarmer.current.getAudioList()]);
    }

    /**
     * Starts playing an alarm sound.
     * @param id The id of the widget that triggered the alarm.
     * @param alarmSound The alarm name to play.
     */
    async function triggerAlarm(id: string, alarmSound: string): Promise<void> {
        debuglog('Alarm Triggered:', id, alarmSound);

        // Set alarm widgets:
        const newAlarms: { [key: string]: string } = { ...alarmWidgets };
        newAlarms[id] = alarmSound;
        await setAlarmWidgets(newAlarms);
    }

    /**
     * Stops playing an alarm sound
     * @param id The id of the widget whose alarm is being dismissed.
     */
    async function dismissAlarm(id: string): Promise<void> {
        debuglog('Alarm Dismissed:', id);

        if (!alarmWidgets[id]) return;

        // Set alarm widgets:
        const newAlarms: { [key: string]: string } = { ...alarmWidgets };
        delete newAlarms[id];
        await setAlarmWidgets(newAlarms);
    }

    /**
     * Handler when the alarm modal is closed.
     */
    function onUploadSoundModalClosed(): void {
        setManageAlarmsVisible(false);

        // Save alarms to local store:
        try {
            localforage.setItem<SerializedAlarmSound[]>('alarms', [...alarmer.current.getSerializedAudioList()]);
            debuglog('Custom Alarms Saved');
        } catch (err) {
            debuglog('Custom Alarm Save Error:', err);
        }
    }

    /**
     * Startup Splash
     */

    const [splashVisible, setSplashVisible] = useState(false);
    const [splashPatchVisible, setSplashPatchVisible] = useState(false);

    /**
     * App Layout
     */

    return (
        <FullScreen handle={fsHandle}>
            <ConfigProvider locale={locale}>
                <Layout style={{ minHeight: '100vh' }}>
                    <Header
                        queueList={queueList}
                        onEditClicked={onEditClicked}
                        onResetLayoutClicked={onResetLayoutClicked}
                        onAddWidgetClicked={(): void => setAddWidgetVisible(true)}
                        onModifyThemeClicked={(): void => setModifyThemeVisible(true)}
                        onSettingsClicked={onSettingsClicked}
                        onImportExportClicked={onImportExportClicked}
                        onManageAlarmsClicked={(): void => setManageAlarmsVisible(true)}
                        onHelpClicked={onHelpClicked}
                        onFullscreenClicked={onFullscreenClicked}
                        editing={editing}
                        fullscreen={fullscreen}
                        authorized={authorized}
                        themeColors={themeColors}
                        title={title}
                        setTitle={setTitle}
                        queueSelected={currentQueues && currentQueues.length > 0}
                        connected={connected}
                        cacheUpdating={cacheUpdating}
                    />
                    <Content>
                        {authorizationError ? (
                            <AuthorizationError authError={authorizationError} timedOut={timedOut} resetConnection={resetConnection} />
                        ) : !authorized ? (
                            <InitConnectForm initConnect={initConnect} loading={initState === InitState.Initialising} />
                        ) : (
                            <WidgetGrid
                                widgetConfigs={widgetConfigs}
                                layout={layout}
                                onLayoutChange={onLayoutChange}
                                onEditWidgetClicked={openEditWidgetModal}
                                queueData={currentQueueData}
                                editing={editing}
                                themeColors={themeColors}
                                darkMode={darkMode}
                                queueList={queueList}
                                currentQueues={currentQueues}
                                queueSelected={setInitialQueue}
                                initComplete={initState === InitState.Complete}
                                triggerAlarm={triggerAlarm}
                                dismissAlarm={dismissAlarm}
                                agentList={agentList}
                                agentData={currentAgentData}
                            />
                        )}
                    </Content>

                    <SettingsModal
                        isVisible={settingsVisible}
                        handleOk={settingsHandleOk}
                        handleCancel={(): void => {
                            setSettingsVisible(false);
                        }}
                        wsTimeoutId={websocketTimeoutId}
                        wsTimeoutDelay={websocketTimeoutDelay}
                        currentLanguage={i18n.language}
                        changeLanguage={i18n.changeLanguage}
                        queueList={queueList}
                        currentQueues={currentQueues}
                        agentList={agentList}
                        currentAgents={currentAgents}
                        username={agentUsername}
                        accountID={accountID}
                        refreshConnectCache={updateConnectCache}
                        cacheUpdating={cacheUpdating}
                    />

                    <EditWidgetModal
                        currentWidget={currentWidget}
                        isVisible={editWidgetVisible}
                        handleOk={onEditWidgetSave}
                        handleCancel={(): void => {
                            setEditWidgetVisible(false);
                        }}
                        handleDelete={(): void => {
                            deleteWidget(currentWidget.widgetid);
                        }}
                        availableMetrics={availableMetrics}
                        availableSlaThresholds={availableSlaThresholds}
                        queueList={queueList}
                        availableAlarms={availableAlarms}
                        openAlarmModal={(): void => setManageAlarmsVisible(true)}
                        availableAgents={agentList}
                        cacheUpdating={cacheUpdating}
                    />

                    <AddWidgetModal
                        isVisible={addWidgetVisible}
                        handleOk={addWidget}
                        handleCancel={(): void => {
                            setAddWidgetVisible(false);
                        }}
                        availableMetrics={availableMetrics}
                        availableSlaThresholds={availableSlaThresholds}
                        queueList={queueList}
                        widgetConfigs={widgetConfigs}
                        availableAlarms={availableAlarms}
                        openAlarmModal={(): void => setManageAlarmsVisible(true)}
                        availableAgents={agentList}
                        cacheUpdating={cacheUpdating}
                    />

                    <ModifyThemeModal
                        isVisible={modifyThemeVisible}
                        handleOk={(): void => setModifyThemeVisible(false)}
                        handleCancel={(): void => {
                            setModifyThemeVisible(false);
                        }}
                        themeColors={themeColors}
                        darkMode={darkMode}
                        setThemeColors={SaveThemeColors}
                        toggleDarkMode={ToggleDarkMode}
                    />

                    <ImportExportModal
                        isVisible={importExportVisible}
                        handleOk={(): void => setImportExportVisible(false)}
                        handleCancel={(): void => {
                            setImportExportVisible(false);
                        }}
                        handleImport={importSkinning}
                        handleExport={exportSkinning}
                    />

                    <HelpModal
                        isVisible={helpVisible}
                        handleOk={(): void => setHelpVisible(false)}
                        handleCancel={(): void => {
                            setHelpVisible(false);
                        }}
                        version={FRONTEND_VERSION}
                    />

                    <ManageAlarmsModal
                        isVisible={manageAlarmsVisible}
                        handleOk={onUploadSoundModalClosed}
                        handleCancel={onUploadSoundModalClosed}
                        alarmList={alarmList}
                        addAlarm={addAlarm}
                        deleteAlarm={deleteAlarm}
                    />

                    <SplashModal.SplashModal
                        isVisible={splashVisible}
                        handleCancel={(): void => {
                            setSplashVisible(false);
                        }}
                    />
                    <SplashModal.SplashPatchModal
                        isVisible={splashPatchVisible}
                        handleCancel={(): void => {
                            setSplashPatchVisible(false);
                        }}
                    />
                </Layout>
            </ConfigProvider>

            <div
                style={{
                    position: 'fixed',
                    width: authorized ? '180px' : '100%',
                    bottom: '16px',
                    right: authorized ? '32px' : '0px',
                    opacity: authorized ? 0.6 : 1,
                    transition: 'opacity 1.5s, width 1s, right 1s',
                }}
            >
                <Branding />
            </div>
        </FullScreen>
    );
};

ReactDOM.render(
    <React.Suspense
        fallback={
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100%',
                }}
            >
                <img src="favicon.png" alt="Rise CX Logo Splash" style={{ opacity: 0.2 }} />
            </div>
        }
    >
        <App />
    </React.Suspense>,
    document.getElementById('root'),
);
