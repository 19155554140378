import { Form, Modal } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ThresholdComponent } from '../../components/WidgetSettingsForm';
import { debuglog } from '../../scripts/debugLog';
import { getDefaultMetricLabel } from '../../scripts/MetricMapping';

export interface ColumnConfig {
    amberThreshold?: number;
    redThreshold?: number;
    blink?: boolean;
}

interface EditTableColumnModalProps {
    isVisible: boolean;
    handleOk: (metric: string, config: ColumnConfig) => void;
    handleCancel: () => void;
    selectedColumn: { metric: string; config: ColumnConfig };
}

function EditTableColumnModal(props: EditTableColumnModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    async function handleOk() {
        try {
            var newConfig: ColumnConfig = props.selectedColumn.config;
            const values = await form.validateFields();

            debuglog('Success', values);

            newConfig = {
                amberThreshold: values.metricamber,
                redThreshold: values.metricred,
                blink: values.metricblink,
            } as ColumnConfig;

            props.handleOk(props.selectedColumn.metric, newConfig);
        } catch (errorInfo) {
            debuglog('Failed:', errorInfo);
        }
    }

    // Build initial form values on modal open:
    React.useEffect(() => {
        form.setFieldsValue({
            metricamber: props.selectedColumn?.config?.amberThreshold || 0,
            metricred: props.selectedColumn?.config?.redThreshold || 0,
            metricblink: props.selectedColumn?.config?.blink || false,
        });
    }, [props.selectedColumn]);

    return (
        <Modal
            title={t('edittablecolumnmodal.title', {
                column: getDefaultMetricLabel(t, props.selectedColumn.metric),
            })}
            visible={props.isVisible}
            onOk={handleOk}
            onCancel={props.handleCancel}
        >
            <Form form={form} layout="vertical" name="editTableRowForm">
                <ThresholdComponent t={t} />
            </Form>
        </Modal>
    );
}

export default EditTableColumnModal;
