import { TFunction } from 'react-i18next';

/**
 * A function to get the default, human-readable label for Amaozn Connect metrics
 * @param t i18next translator function
 * @param metricid The metric id to get the label for
 * @returns The default label for the metric id
 */

export function getDefaultMetricLabel(t: TFunction<'translation', undefined>, metricid: string): string {
    switch (metricid) {
        case 'CONTACTS_QUEUED':
            return t('widgets.defaults.contactsqueued');

        case 'CONTACTS_HANDLED':
            return t('widgets.defaults.contactshandled');

        case 'CONTACTS_HANDLED_OUTBOUND':
            return t('widgets.defaults.contactshandledoutbound');

        case 'SERVICE_LEVEL':
            return t('widgets.defaults.servicelevel');

        case 'CONTACTS_ABANDONED':
            return t('widgets.defaults.contactsabandoned');

        case 'QUEUE_ANSWER_TIME':
            return t('widgets.defaults.queueanswertime');

        case 'CONTACTS_IN_QUEUE':
            return t('widgets.defaults.contactsinqueue');

        case 'OLDEST_CONTACT_AGE':
            return t('widgets.defaults.oldestcontactage');

        case 'AGENTS_ONLINE':
            return t('widgets.defaults.agentsonline');

        case 'AGENTS_NON_PRODUCTIVE':
            return t('widgets.defaults.agentsnonproductive');

        case 'AGENTS_AVAILABLE':
            return t('widgets.defaults.agentsavailable');

        case 'HANDLE_TIME':
            return t('widgets.defaults.handletime');

        case 'AGENTS_ON_CALL':
            return t('widgets.defaults.agentsoncall');

        case 'INTERACTION_TIME':
            return t('widgets.defaults.interactiontime');

        default:
            return metricid;
    }
}
