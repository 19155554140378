import { Form, Modal } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../components/TimeSince';
import { TimeThresholdComponent } from '../../components/WidgetSettingsForm';
import { debuglog } from '../../scripts/debugLog';

export interface ColumnConfig {
    amberThreshold?: number;
    redThreshold?: number;
    blink?: boolean;
}

interface EditAgentStatusColumnModalProps {
    isVisible: boolean;
    handleOk: (config: ColumnConfig) => void;
    handleCancel: () => void;
    selectedColumn: ColumnConfig;
}

function EditAgentStatusColumnModal(props: EditAgentStatusColumnModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    function parseValue(value: string): number {
        const padded = `${value}`.padStart(6, '0');
        const formatted = padded.replace(/\B(?=(\d{2}){1,2}(?!\d))/g, ':');
        const split = formatted.split(':');
        const hrs = parseInt(split[0]);
        const min = parseInt(split[1]);
        const sec = parseInt(split[2]);
        const millis = (hrs * 60 * 60 + min * 60 + sec) * 1000;
        debuglog('parse', value, padded, split, millis);
        return millis;
    }

    async function handleOk() {
        try {
            let newConfig: ColumnConfig = props.selectedColumn;
            const values = await form.validateFields();

            let amber = parseValue(values.metricamber);
            let red = parseValue(values.metricred);

            newConfig = {
                amberThreshold: amber,
                redThreshold: red,
                blink: values.metricblink,
            } as ColumnConfig;

            debuglog('statusSuccess', values, newConfig);

            props.handleOk(newConfig);
        } catch (errorInfo) {
            debuglog('Failed:', errorInfo);
        }
    }

    // Build initial form values on modal open:
    React.useEffect(() => {
        form.setFieldsValue({
            metricamber: props.selectedColumn?.amberThreshold ? formatTime(props.selectedColumn.amberThreshold) : 0,
            metricred: props.selectedColumn?.redThreshold ? formatTime(props.selectedColumn.redThreshold) : 0,
            metricblink: props.selectedColumn?.blink || false,
        });
    }, [props.selectedColumn]);

    return (
        <Modal
            title={t('edittablecolumnmodal.title', {
                column: t('widgets.agent.headers.time'),
            })}
            visible={props.isVisible}
            onOk={handleOk}
            onCancel={props.handleCancel}
        >
            <Form form={form} layout="vertical" name="editTableRowForm">
                <TimeThresholdComponent t={t} />
            </Form>
        </Modal>
    );
}

export default EditAgentStatusColumnModal;
