import { CaretRightOutlined, DeleteOutlined, SoundOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Space, Upload } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmSound } from '../components/Alarmer';
import { debuglog } from '../scripts/debugLog';

const { Dragger } = Upload;

interface ManageAlarmsModalProps {
    isVisible: boolean;
    handleOk: () => void;
    handleCancel: any;
    alarmList: AlarmSound[];
    addAlarm: (alarm: AlarmSound) => void;
    deleteAlarm: (name: string) => void;
}

/**
 * The Manage Alarms modal. Opens when clicking the alarms button in the header, or within the widget settings.
 */
function ManageAlarmsModal(props: ManageAlarmsModalProps) {
    const { t } = useTranslation();

    const [audioCtx] = useState<AudioContext>(new AudioContext());

    async function handleOk() {
        await props.handleOk();
    }

    const draggerProps = {
        accept: 'audio/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            if (props.alarmList.length >= 5) {
                message.warning(t('managealarmsmodal.messages.toomany'));
                return;
            }

            if (props.alarmList.find((a) => a.name === file.name)) {
                message.error(t('managealarmsmodal.messages.duplicate'));
                return;
            }

            debuglog('Alarm file: ', file);
            const reader = new FileReader();

            reader.onload = (e) => {
                debuglog('IMPORT alarm', e.target.result);
                audioCtx.decodeAudioData(
                    e.target.result as ArrayBuffer,
                    function (buffer) {
                        if (buffer.duration > 2) {
                            message.error(t('managealarmsmodal.messages.toolong'));
                            return;
                        }

                        debuglog('Alarm Buffer Decode', buffer);
                        props.addAlarm({
                            name: file.name,
                            buffer: buffer,
                        });
                    },

                    function (e) {
                        debuglog('Error with decoding audio data', e);
                        message.error({ content: t('managealarmsmodal.messages.invalidfile') });
                    },
                );
            };
            reader.readAsArrayBuffer(file);
            // Prevent upload
            return false;
        },
    };

    return (
        <Modal
            title={t('managealarmsmodal.title')}
            visible={props.isVisible}
            onOk={handleOk}
            onCancel={props.handleCancel}
            footer={[
                <Button type="primary" key="ok" onClick={props.handleOk}>
                    OK
                </Button>,
            ]}
        >
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <SoundOutlined />
                </p>
                <p className="ant-upload-text">{t('managealarmsmodal.upload.text')}</p>
                <p className="ant-upload-hint">{t('managealarmsmodal.upload.hint')}</p>
            </Dragger>

            {props.alarmList.length > 0 && (
                <>
                    <Divider />

                    <Space direction="vertical" style={{ width: '100%' }}>
                        {props.alarmList.map((v, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    {props.alarmList[i].name}

                                    <Space>
                                        {props.alarmList[i].name != 'Default Alarm 1' && props.alarmList[i].name != 'Default Alarm 2' && (
                                            <Button
                                                onClick={() => {
                                                    props.deleteAlarm(props.alarmList[i].name);
                                                }}
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => {
                                                const sound = props.alarmList[i];
                                                const source = audioCtx.createBufferSource();
                                                source.buffer = sound.buffer;
                                                source.connect(audioCtx.destination);
                                                source.start();
                                            }}
                                        >
                                            <CaretRightOutlined />
                                        </Button>
                                    </Space>
                                </div>
                            );
                        })}
                    </Space>
                </>
            )}
        </Modal>
    );
}

export default ManageAlarmsModal;
