import { Space, Tooltip } from 'antd';
import React from 'react';

export interface WidgetButton {
    icon: React.ReactElement;
    tooltip: string;
    onClick: () => void;
}

interface WidgetButtonsProps {
    editing: boolean;
    buttons: WidgetButton[];
}

/**
 * A component for the buttons shown in the corner of a widget when editing the widget grid.
 */
export default function WidgetButtons(props: WidgetButtonsProps) {
    return (
        <Space direction="vertical" hidden={!props.editing} style={{ position: 'absolute', top: '8px', right: '8px' }}>
            {props.buttons.map((b, i) => {
                return (
                    <Tooltip key={i} placement="left" title={b.tooltip}>
                        <a onClick={b.onClick}>{b.icon}</a>
                    </Tooltip>
                );
            })}
        </Space>
    );
}
