import * as React from 'react';

import { Button, Collapse, Divider, Form, InputNumber, Modal, Select, Space, Switch, Typography } from 'antd';
import { AgentModel } from '../widgets/agent/AgentWidget';

// Localisation:
import { useTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

interface QueueConfigModalProps {
    isVisible: boolean;
    handleOk: (enabled: boolean, time: number) => void;
    handleCancel: any;
    wsTimeoutId: number;
    wsTimeoutDelay: number;
    currentLanguage: string;
    changeLanguage: (l: string) => {};
    queueList: { name: string; id: string }[];
    currentQueues: string[][];
    agentList: AgentModel[];
    currentAgents: string[];
    username: string;
    accountID: string;
    refreshConnectCache: () => void;
    cacheUpdating: boolean;
}

/**
 * The Settings modal. Opens when clicking the settings button in the header.
 */
function SettingsModal(props: QueueConfigModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [timeoutEnabled, setTimeoutEnabled] = React.useState(false);
    const [timeoutTime, setTimeoutTime] = React.useState(8);
    const languageList = [
        { key: 'en-UK', value: 'English (UK)' },
        { key: 'en-US', value: 'English (US)' },
    ];

    React.useEffect(() => {
        setTimeoutEnabled(props.wsTimeoutId != null);
        return;
    }, [props.wsTimeoutId]);

    React.useEffect(() => {
        setTimeoutTime(props.wsTimeoutDelay);
        return;
    }, [props.wsTimeoutDelay]);

    function disconnectOnChange(value: boolean) {
        setTimeoutEnabled(value);
    }

    function disconnectTimeOnChange(value: number) {
        setTimeoutTime(value);
    }

    async function handleOk() {
        await props.handleOk(timeoutEnabled, timeoutTime);
    }

    return (
        <Modal title={t('settingsmodal.title')} visible={props.isVisible} onOk={handleOk} onCancel={props.handleCancel}>
            <Form form={form} layout="horizontal" name="timeoutForm">
                <Form.Item label={t('settingsmodal.timeoutenabled')} name="timeoutenabled" valuePropName="checked" initialValue={timeoutEnabled}>
                    <Switch onChange={disconnectOnChange} checked={timeoutEnabled} />
                </Form.Item>
                {timeoutEnabled && (
                    <Form.Item label={t('settingsmodal.timeouttime.label')}>
                        <Space>
                            <InputNumber disabled={!timeoutEnabled} min={1} max={120} value={timeoutTime} onChange={disconnectTimeOnChange} />
                            {t('settingsmodal.timeouttime.suffix')}
                        </Space>
                    </Form.Item>
                )}
                <Form.Item label={t('settingsmodal.language.label')} name="language" valuePropName="checked" initialValue={timeoutEnabled}>
                    <Select value={props.currentLanguage} onChange={props.changeLanguage} showSearch>
                        {languageList.map((l) => (
                            <Select.Option key={l.key} value={l.key}>
                                {l.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>

            <Divider>{t('settingsmodal.instance.divider')}</Divider>

            <Space direction="vertical" style={{ width: '100%' }}>
                <Collapse>
                    <Panel header={t('settingsmodal.instance.queues')} key="1">
                        {props.currentQueues.length > 0 ? (
                            <ul>
                                {props.queueList.length > 0 &&
                                    props.currentQueues.map((aggregatedQueues, i) => {
                                        return (
                                            <li key={i}>
                                                {aggregatedQueues
                                                    .map((q) => props.queueList.find((queue) => queue.id === q).name)
                                                    .sort()
                                                    .join(' + ')}
                                            </li>
                                        );
                                    })}
                            </ul>
                        ) : (
                            <Text type="secondary">{t('settingsmodal.instance.none')}</Text>
                        )}
                    </Panel>
                    <Panel header={t('settingsmodal.instance.agents')} key="2">
                        {props.currentAgents.length > 0 ? (
                            <ul>
                                {props.agentList.length > 0 &&
                                    props.currentAgents.map((id, i) => {
                                        const a = props.agentList.find((agent) => agent.id === id);
                                        if (!a) return;
                                        return <li key={i}>{a.identity ? `${a.identity.name} (${a.username})` : a.username}</li>;
                                    })}
                            </ul>
                        ) : (
                            <Text type="secondary">{t('settingsmodal.instance.none')}</Text>
                        )}
                    </Panel>
                </Collapse>

                <Space style={{ width: '100%' }} direction="vertical" align="end">
                    <Button danger disabled={props.cacheUpdating} onClick={props.refreshConnectCache}>
                        {t('settingsmodal.instance.refreshcache')}
                    </Button>
                </Space>
            </Space>

            <Divider>{t('settingsmodal.session.divider')}</Divider>

            <Paragraph>
                {t('settingsmodal.session.accountid', { accountid: props.accountID })}
                <br />
                {t('settingsmodal.session.username', { username: props.username })}
            </Paragraph>
            <Paragraph></Paragraph>
        </Modal>
    );
}

export default SettingsModal;
