'use strict';

import { Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { MultipleMetricSelectionComponent, QueueSelectionComponent } from '../../components/WidgetSettingsForm';

export default function TableWidgetSettings(
    t: any,
    availableMetrics: string[],
    availableSlaThresholds: string[],
    selectedMetrics: string[],
    setSelectedMetrics: (value: string[]) => void,
    setSelectedQueues: (value: string[]) => void,
    queueList: { name: string; id: string }[],
    selectedQueues: string[],
    cacheUpdating: boolean,
) {
    return (
        <div>
            {MultipleMetricSelectionComponent(t, availableMetrics, availableSlaThresholds, selectedMetrics, setSelectedMetrics)}

            {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues, cacheUpdating)}

            <Form.Item label={t('widgetsettingsform.tablewidget.tablelabel.label')} name="tablelabel">
                <Input />
            </Form.Item>

            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        label={t('widgetsettingsform.tablewidget.valuesize.label')}
                        name="valuesize"
                        rules={[
                            {
                                required: true,
                                message: t('widgetsettingsform.labelwidget.valuesize.required'),
                            },
                        ]}
                    >
                        <InputNumber min={12} max={48} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t('widgetsettingsform.tablewidget.headingsize.label')}
                        name="headingsize"
                        rules={[
                            {
                                required: true,
                                message: t('widgetsettingsform.labelwidget.headingsize.required'),
                            },
                        ]}
                    >
                        <InputNumber min={12} max={48} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
