import * as React from 'react';

import { Modal, Collapse, Row, Space, Switch, Button } from 'antd';
import { changeAntdTheme } from '../antd-theme-switcher/index';
import ColorPicker from '../components/ColorPicker';

const { Panel } = Collapse;

import { debuglog } from '../scripts/debugLog';

// Localisation:
import { useTranslation } from 'react-i18next';

export interface ThemeColors {
    primaryColor: string;
    headerColor: string;
    amberColor: string;
    redColor: string;
}

interface ModifyThemeModalProps {
    isVisible: boolean;
    handleOk: any;
    handleCancel: any;
    themeColors: ThemeColors;
    darkMode: boolean;
    setThemeColors: (t: ThemeColors) => void;
    toggleDarkMode: (on: boolean) => void;
}

/**
 * The Modify Theme modal. Opens when clicking the modify theme button in the header.
 */
function ModifyThemeModal(props: ModifyThemeModalProps) {
    const { t } = useTranslation();

    async function onColorChange(nextColor) {
        const mergedNextColor: any = {
            ...props.themeColors,
            ...nextColor,
        };

        await props.setThemeColors(mergedNextColor);

        debuglog(nextColor);

        if (nextColor.primaryColor) {
            await changeAntdTheme(nextColor.primaryColor, { darkMode: props.darkMode });
        }
    }

    async function onDarkModeToggle(checked: boolean) {
        props.toggleDarkMode(checked);
        await changeAntdTheme(props.themeColors.primaryColor, { darkMode: checked });
    }

    return (
        <Modal
            title={t('modifythememodal.title')}
            visible={props.isVisible}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
            footer={[
                <Row key="editwidgetfooter" justify="space-between">
                    <Space>
                        <Switch defaultChecked={props.darkMode} onChange={onDarkModeToggle} />
                        {t('modifythememodal.footer.darkmode')}
                    </Space>
                    <Space>
                        <Button key="back" onClick={props.handleCancel}>
                            {t('common.cancel')}
                        </Button>
                        <Button key="submit" type="primary" onClick={props.handleOk}>
                            {t('common.save')}
                        </Button>
                    </Space>
                </Row>,
            ]}
        >
            <Collapse>
                <Panel key="1" header={t('modifythememodal.themecolors')}>
                    <Row>
                        <Space size="small">
                            <ColorPicker
                                presetColors={['#1890ff', '#25b864', '#ff6f00']}
                                color={props.themeColors.primaryColor}
                                onChange={async ({ hex }) => {
                                    await onColorChange({
                                        primaryColor: hex,
                                    });
                                }}
                            />
                            {t('modifythememodal.primarycolor')}
                        </Space>
                    </Row>

                    <Row>
                        <Space size="small">
                            <ColorPicker
                                presetColors={['#1890ff', '#25b864', '#ff6f00']}
                                color={props.themeColors.headerColor}
                                onChange={async ({ hex }) => {
                                    await onColorChange({
                                        headerColor: hex,
                                    });
                                }}
                            />
                            {t('modifythememodal.headercolor')}
                        </Space>
                    </Row>
                </Panel>
                <Panel key="2" header={t('modifythememodal.thresholdcolors')}>
                    <Row>
                        <Space size="small">
                            <ColorPicker
                                presetColors={['#fa8c16']}
                                color={props.themeColors.amberColor}
                                onChange={async ({ hex }) => {
                                    await onColorChange({
                                        amberColor: hex,
                                    });
                                }}
                            />
                            {t('modifythememodal.ambercolor')}
                        </Space>
                    </Row>

                    <Row>
                        <Space size="small">
                            <ColorPicker
                                presetColors={['#f5222d']}
                                color={props.themeColors.redColor}
                                onChange={async ({ hex }) => {
                                    await onColorChange({
                                        redColor: hex,
                                    });
                                }}
                            />
                            {t('modifythememodal.redcolor')}
                        </Space>
                    </Row>
                </Panel>
            </Collapse>
        </Modal>
    );
}

export default ModifyThemeModal;
