'use strict';

import { Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * A small component showing the RiseCX logo.
 */
function Branding() {
    const { t } = useTranslation();

    return (
        <Space style={{ width: '100%', padding: '12px 0' }} direction="vertical" align="center">
            <Space direction="horizontal" align="center">
                <img src="favicon.png" alt="Rise CX Logo" style={{ height: 28, width: 28 }} />
                <Text>{t('branding')}</Text>
            </Space>
        </Space>
    );
}

export default Branding;
