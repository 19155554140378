'use strict';

import { Form, Input } from 'antd';
import React from 'react';
import { MetricSelectionComponent, QueueSelectionComponent, ThresholdComponent } from '../../components/WidgetSettingsForm';

export default function MetricWidgetSettings(
    t: any,
    availableMetrics: string[],
    availableSlaThresholds: string[],
    selectedMetric: string,
    setSelectedMetric: React.Dispatch<React.SetStateAction<string>>,
    setSelectedQueues: (value: string[]) => void,
    queueList: { name: string; id: string }[],
    selectedQueues: string[],
    availableAlarms: string[],
    openAlarmModal: () => void,
    cacheUpdating: boolean,
) {
    return (
        <div>
            {MetricSelectionComponent(t, availableMetrics, availableSlaThresholds, selectedMetric, setSelectedMetric)}

            {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues, cacheUpdating)}

            <Form.Item label={t('widgetsettingsform.metricwidget.metriclabel.label')} name="metriclabel">
                <Input />
            </Form.Item>

            <ThresholdComponent t={t} showAlarms availableAlarms={availableAlarms} openAlarmModal={openAlarmModal} />
        </div>
    );
}
