import * as React from 'react';
import { useState } from 'react';

import { Line, LineConfig } from '@ant-design/charts';
import { SettingOutlined } from '@ant-design/icons';
import { Card, Typography } from 'antd';
import WidgetButtons from '../../components/WidgetButtons';
import { Metric, WidgetDataConfig } from '../../components/WidgetGrid';

const { Text } = Typography;

import { debuglog } from '../../scripts/debugLog';

// Localisation:
import { useTranslation } from 'react-i18next';

export interface ChartWidgetConfig extends WidgetDataConfig {
    timerange: number;
}

interface ChartWidgetProps {
    id: string;
    config: ChartWidgetConfig;
    metric: Metric;
    editing: boolean;
    onEditWidgetClicked: any;
    color: string;
    queueList: { name: string; id: string }[];
    darkMode: boolean;
}

function ChartWidget(props: ChartWidgetProps) {
    const { t } = useTranslation();

    const chartRef = React.useRef();

    const [datapoints, setDataPoints] = useState(180);
    const [queueName, setQueueName] = useState('');
    const [data, setData] = useState<{ time: string; value: number }[]>([]);

    const config: LineConfig = {
        data: data,
        autoFit: true,
        xField: 'time',
        yField: 'value',
        color: props.color,
        theme: props.darkMode ? 'dark' : 'default',
    };

    React.useEffect(() => {
        // timerange x 60 (as seconds) / 5 (seconds per poll)
        setDataPoints(props.config.timerange * 12);
        debuglog('New time range for chart: ', props.config.timerange * 12);
        return;
    }, [props.config.timerange]);

    React.useEffect(() => {
        if (!props.queueList || props.queueList.length === 0 || !props.config.queues || props.config.queues.length === 0) return;

        if (props.config.queues.length === 1) {
            var queue = props.queueList.find((q) => q.id === props.config.queues[0]);
            if (queue) setQueueName(queue.name);
        } else {
            setQueueName(t('widgets.common.multiqueue'));
        }

        return;
    }, [props.queueList, props.config.queues]);

    React.useEffect(() => {
        debuglog('cleared chart data');
        var newData = data;
        while (newData.length > 0) newData.shift();
        setData(newData);

        return;
    }, [props.config.metricid]);

    React.useEffect(() => {
        if (!props.metric) return;

        var newData = data.slice();

        let val = parseInt(props.metric.value);
        let datum = {
            time: new Date(Date.now()).toLocaleTimeString(),
            value: val,
        };

        newData.push(datum);
        while (newData.length > datapoints) newData.shift();

        debuglog('pushed data to chart', datum, newData);

        setData(newData);

        return;
    }, [props.metric]);

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    return (
        <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
            <div
                style={{
                    height: '15%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text>{props.config.label}</Text>
                <div className="ant-statistic-title">{queueName}</div>
            </div>
            <div style={{ height: '85%' }}>
                <Line ref={chartRef} {...config} />
            </div>

            <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />
        </Card>
    );
}

{
    /* <div className="card o-hidden" >
< div className="chart-container" >
    <div className="card-header">
        <h3 style={{ textAlign: 'center', fontSize: '1.4vw' }} id="chartDate"></h3>
    </div>
    <div className="card-body">
        <canvas id="myAreaChart"></canvas>

    </div>
</div >
</div > */
}

export default ChartWidget;
