import * as React from 'react';

import { Button, Popconfirm, Space, Typography } from 'antd';
import {
    EditOutlined,
    CheckOutlined,
    UndoOutlined,
    PlusCircleOutlined,
    SettingOutlined,
    ExpandOutlined,
    CompressOutlined,
    BgColorsOutlined,
    WarningOutlined,
    ImportOutlined,
    QuestionCircleOutlined,
    SoundOutlined,
} from '@ant-design/icons';

const { Title } = Typography;

import tinycolor from 'tinycolor2';
import { ThemeColors } from '../modals/ModifyThemeModal';

// Localisation:
import { useTranslation } from 'react-i18next';
import ConnectionLight from './ConnectionLight';
import SyncLight from './SyncLight';

interface HeaderProps {
    queueList: { name: string; id: string }[];
    onEditClicked: any;
    onResetLayoutClicked: any;
    onAddWidgetClicked: any;
    onSettingsClicked: any;
    onModifyThemeClicked: any;
    onImportExportClicked: any;
    onManageAlarmsClicked: any;
    onHelpClicked: any;
    onFullscreenClicked: any;
    editing: boolean;
    fullscreen: boolean;
    authorized: boolean;
    themeColors: ThemeColors;
    title: string;
    setTitle: (t: string) => void;
    queueSelected: boolean;
    connected: boolean;
    cacheUpdating: boolean;
}

/**
 * The header component.
 */
function Header(props: HeaderProps) {
    const { t } = useTranslation();

    function onTitleChange(title: string) {
        props.setTitle(title);
    }

    return (
        <div
            id="header"
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: '8px',
                backgroundColor: tinycolor(props.themeColors.headerColor).setAlpha(0.7).toString(),
            }}
        >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <img src="favicon.png" alt="Rise CX Logo" style={{ height: 24, width: 24, margin: '0 8px' }} />
                <Title
                    id="header-title"
                    level={4}
                    style={{
                        margin: '0',
                        paddingLeft: '8px',
                        color: props.themeColors.primaryColor,
                        transitionDuration: '0.5s',
                    }}
                    editable={props.editing ? { tooltip: t('header.edittitle'), onChange: onTitleChange } : false}
                >
                    {props.title}
                </Title>
            </div>

            <Space hidden={props.editing || props.fullscreen ? true : false}>
                <Space hidden={props.authorized ? false : true}>
                    {props.authorized && <SyncLight updating={props.cacheUpdating} />}
                    {props.authorized && <ConnectionLight connected={props.connected} />}
                    <Button onClick={props.onEditClicked}>
                        <EditOutlined />
                        {t('header.editwallboard')}
                    </Button>
                    <Button onClick={props.onSettingsClicked}>
                        <SettingOutlined />
                        {t('header.settings')}
                    </Button>
                </Space>

                <Button onClick={props.onHelpClicked}>
                    <QuestionCircleOutlined />
                    {t('header.help')}
                </Button>

                <Button onClick={props.onFullscreenClicked}>
                    <ExpandOutlined />
                    {t('header.fullscreen')}
                </Button>
            </Space>

            <Space hidden={props.editing ? false : true}>
                <Popconfirm
                    key="deleteconfirm"
                    title={t('header.resetlayoutconfirm')}
                    onConfirm={props.onResetLayoutClicked}
                    okText={t('common.yes')}
                    cancelText={t('common.cancel')}
                    icon={<WarningOutlined style={{ color: 'red' }} />}
                >
                    <Button>
                        <UndoOutlined />
                        {t('header.resetlayout')}
                    </Button>
                </Popconfirm>
                <Button onClick={props.onImportExportClicked}>
                    <ImportOutlined />
                    {t('header.importexport')}
                </Button>
                <Button onClick={props.onAddWidgetClicked} disabled={!props.queueSelected}>
                    <PlusCircleOutlined />
                    {t('header.addwidget')}
                </Button>
                <Button onClick={props.onManageAlarmsClicked}>
                    <SoundOutlined />
                    {t('header.managealarms')}
                </Button>
                <Button onClick={props.onModifyThemeClicked}>
                    <BgColorsOutlined />
                    {t('header.modifytheme')}
                </Button>
                <Button onClick={props.onEditClicked} type="primary">
                    <CheckOutlined />
                    {t('header.finishediting')}
                </Button>
            </Space>

            <Space hidden={props.fullscreen ? false : true}>
                <Button onClick={props.onFullscreenClicked}>
                    <CompressOutlined />
                    {t('header.exitfullscreen')}
                </Button>
            </Space>
        </div>
    );
}

export default Header;
