import { debuglog } from '../scripts/debugLog';

export interface SerializedAlarmSound {
    name: string;
    buffer: Float32Array;
}

export interface AlarmSound {
    name: string;
    buffer: AudioBuffer;
}

/**
 * Alarmer manages the sounds when alarming is enabled.
 */
export class Alarmer {
    playing = false;
    audioList: AlarmSound[] = [];
    alarmQueue: AudioBuffer[] = [];
    alarmIndex = 0;
    audioCtx: AudioContext = new AudioContext();

    constructor() {
        this.playing = false;
        this.alarmQueue = [];
        this.alarmIndex = 0;
    }

    /**
     * Sets the current AlarmSounds to be played in sequence. This must be set before startAlarm() to ensure there are AlarmSounds queued to be played.
     * @param alarmNames The AlarmSounds to play in sequence
     */
    setAlarms(alarmNames: string[]) {
        debuglog('Setting Alarms: ', alarmNames, this.audioList);

        this.alarmQueue = [];
        for (const a in alarmNames) {
            const alarm = alarmNames[a];
            const audio = this.audioList.find((ai) => ai.name === alarm);
            if (audio) {
                this.alarmQueue.push(audio.buffer);
            }
        }
        debuglog('Alarms Set: ', this.alarmQueue);
    }

    /**
     * Initialises required parameters to start running alarm sequence
     */
    startAlarm() {
        this.playing = true;
        this.alarmIndex = 0;
        this.audioCtx.resume();
        debuglog('Starting Alarm: ', this.alarmQueue);
    }

    /**
     * Stops the alarming sequence and reset parameters
     */
    stopAlarm() {
        this.playing = false;
        this.alarmQueue = [];
        this.alarmIndex = 0;
        this.audioCtx.suspend();
        debuglog('Stopping Alarm');
    }

    /**
     * Plays the next alarm in sequence
     */
    runAlarm() {
        debuglog('Running Alarm: ', this.alarmQueue, this.alarmIndex);
        if (this.alarmQueue.length === 0) return;

        // Exit early if stopped:
        if (!this.playing) return;

        // Play next alarm in queue:
        const source = this.audioCtx.createBufferSource();
        source.buffer = this.alarmQueue[this.alarmIndex];
        source.connect(this.audioCtx.destination);
        source.start();

        if (this.alarmIndex >= this.alarmQueue.length - 1) {
            this.alarmIndex = 0;
        } else {
            this.alarmIndex++;
        }
    }

    /**
     * Adds the provided AlarmSound to the cached list
     * @param alarm The AlarmSound to add
     */
    addToAudioList(alarm: AlarmSound) {
        this.audioList.push(alarm);
    }

    /**
     * Remove a specified AlarmSound from the cached list
     * @param name The name of the AlarmSound to remove
     */
    removeFromAudioList(name: string) {
        let newAudioList: AlarmSound[] = [];
        for (let a = 0; a < this.audioList.length; a++) {
            if (this.audioList[a].name === name) continue;
            newAudioList.push(this.audioList[a]);
        }
        this.audioList = newAudioList;
    }

    /**
     * Gets the current list of cached AlarmSounds
     * @returns An array of AlarmSounds
     */
    getAudioList(): AlarmSound[] {
        return this.audioList;
    }

    /**
     * Loads default sounds and any specified custom sounds to cached alarm list
     * @param customSounds A list of SerializedAlarmSounds (that use Float32Buffer) to add
     */
    async loadSounds(customSounds: SerializedAlarmSound[]) {
        await this.loadDefaultSound('Default Alarm 1', 'wb-alarm-1.mp3');
        await this.loadDefaultSound('Default Alarm 2', 'wb-alarm-2.mp3');

        if (customSounds) {
            await customSounds.forEach(async (v) => {
                await this.loadSound(v);
            });
        }
    }

    private async loadDefaultSound(name: string, filename: string) {
        let res = await fetch(filename);
        let v = await res.arrayBuffer();
        let buffer = await this.audioCtx.decodeAudioData(v);
        this.audioList.push({
            name: name,
            buffer: buffer,
        });
        debuglog('Loaded Default Alarm - ' + name + ':', this.audioList);
    }

    private async loadSound(sound: SerializedAlarmSound) {
        try {
            let buffer = this.audioCtx.createBuffer(1, sound.buffer.byteLength, 48000);
            buffer.copyToChannel(sound.buffer, 0);
            this.audioList.push({
                name: sound.name,
                buffer: buffer,
            });
            debuglog('Loaded Custom Alarm - ' + sound.name + ':', this.audioList);
        } catch (err) {
            debuglog('Loaded Custom Alarm Error - ' + sound.name + ':', err);
        }
    }

    /**
     * Converts audio list into a serializable object array
     * @returns The audio list converted with Float32Buffer, rather than AudioBuffer
     */
    getSerializedAudioList(): SerializedAlarmSound[] {
        let newAudioList: SerializedAlarmSound[] = [];

        this.audioList.forEach((as) => {
            if (as.name === 'Default Alarm 1' || as.name === 'Default Alarm 2') return;
            const arrayBuffer = new Float32Array(as.buffer.length);
            as.buffer.copyFromChannel(arrayBuffer, 0);
            newAudioList.push({
                name: as.name,
                buffer: arrayBuffer,
            });
        });

        return newAudioList;
    }
}
