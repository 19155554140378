import * as React from 'react';

import { Button, Checkbox, Form, Input, Typography } from 'antd';

import { debuglog } from '../scripts/debugLog';

const { Paragraph } = Typography;

// Localisation:
import { useTranslation } from 'react-i18next';

interface InitConnectModalProps {
    initConnect: (instanceURL: string, ssoURL: string) => void;
    loading: boolean;
}

/**
 * The initialisation form that attempts to authenticate the user with Amazon Connect Streams API.
 */
function InitConnectModal(props: InitConnectModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    React.useEffect(() => {
        if (global.localStorage) {
            try {
                form.setFieldsValue(JSON.parse(global.localStorage.getItem('wallboard-connection')));
            } catch {}
        }
    }, []);

    async function submitForm(values: any) {
        var instanceURL = values['instanceURL'];
        var ssoURL = values['ssoURL'];
        var remember = values['remember'];

        debuglog(values);

        if (global.localStorage) {
            global.localStorage.setItem('wallboard-rememberconnection', JSON.stringify(remember));

            if (remember) {
                global.localStorage.setItem('wallboard-connection', JSON.stringify(values));
            } else {
                global.localStorage.removeItem('wallboard-connection');
            }
        }

        await props.initConnect(instanceURL, ssoURL);
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '640px',
                    padding: '48px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <Form form={form} layout="vertical" name="initConnectConfig" onFinish={submitForm} onFinishFailed={null} requiredMark={false} initialValues={{ remember: true }}>
                    <Paragraph>{t('initconnectform.description')}</Paragraph>

                    <Form.Item
                        label={t('initconnectform.instanceurl.label')}
                        name="instanceURL"
                        rules={[
                            () => ({
                                validator(_, value: string) {
                                    if (value.match(/(http|https):\/\/(.*)(\.my\.connect\.aws|\.awsapps\.com)/g)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(new Error(t('initconnectform.instanceurl.invalid')));
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input placeholder={t('initconnectform.instanceurl.placeholder')} />
                    </Form.Item>

                    <Form.Item label={t('initconnectform.ssourl.label')} name="ssoURL">
                        <Input placeholder={t('initconnectform.ssourl.placeholder')} />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>{t('initconnectform.remember')}</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={props.loading}>
                            {t('initconnectform.submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default InitConnectModal;
