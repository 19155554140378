'use strict';

import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

interface ColorPickerProps {
    color: string;
    presetColors: string[];
    onChange: (hex: any) => {};
}

/**
 * A custom colour picker that looks nice.
 */
function ColorPicker(props: ColorPickerProps) {
    const [show, setShow] = React.useState(false);

    function handleClick() {
        setShow(!show);
    }

    function handleClose() {
        setShow(false);
    }

    function handleChange(color) {
        props.onChange(color);
    }

    const styles = reactCSS({
        default: {
            color: {
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: props.color,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '50%',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                color: 'black',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {show ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker presetColors={props.presetColors} color={props.color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
}

export default ColorPicker;
