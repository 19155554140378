import { SettingOutlined } from '@ant-design/icons';
import { Gauge, GaugeConfig } from '@ant-design/plots';
import { Card, Typography } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetButtons from '../../components/WidgetButtons';
import { Metric, WidgetDataConfig } from '../../components/WidgetGrid';
import { debuglog } from '../../scripts/debugLog';

const { Text } = Typography;

export interface GaugeWidgetConfig extends WidgetDataConfig {
    maximum: number;
    amberThreshold: number;
    redThreshold: number;
    amberSound: string;
    redSound: string;
    blink: boolean;
    sla: string;
}

interface GaugeWidgetProps {
    id: string;
    config: GaugeWidgetConfig;
    metric: Metric;
    editing: boolean;
    onEditWidgetClicked: any;
    amberColor: string;
    redColor: string;
    queueList: { name: string; id: string }[];
    triggerAlarm: (id: string, alarmSound: string) => void;
    dismissAlarm: (id: string) => void;
}

const greenColor = '#30BF78';

function GaugeWidget(props: GaugeWidgetProps) {
    const { t } = useTranslation();

    const [labelColor, setLabelColor] = useState('');
    const [blink, setBlink] = useState(false);

    const [queueName, setQueueName] = useState('');
    const [value, setValue] = useState(0);

    const [range, setRange] = useState({
        ticks: [0, 1 / 3, 2 / 3, 1],
        color: [props.redColor, props.amberColor, greenColor],
        width: 12,
    });

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    React.useEffect(() => {
        if (!props.queueList || props.queueList.length === 0 || !props.config.queues || props.config.queues.length === 0) return;

        if (props.config.queues.length === 1) {
            var queue = props.queueList.find((q) => q.id === props.config.queues[0]);
            if (queue) setQueueName(queue.name);
        } else {
            setQueueName(t('widgets.common.multiqueue'));
        }

        return;
    }, [props.queueList, props.config.queues]);

    React.useEffect(() => {
        if (!props.metric) return;

        const amber = props.config.amberThreshold || 0;
        const red = props.config.redThreshold || 0;
        const checkLess = red < amber;
        const val = parseInt(props.metric.value);

        if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
            // Red triggered:
            setLabelColor(props.redColor);
            if (props.config.blink) setBlink(true);
            else setBlink(false);
        } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
            // Amber triggered:
            setLabelColor(props.amberColor);
            setBlink(false);
        } else {
            // No threshold:
            setLabelColor(greenColor);
            setBlink(false);
        }

        const rangeticks = checkLess ? [0, red / props.config.maximum, amber / props.config.maximum, 1] : [0, amber / props.config.maximum, red / props.config.maximum, 1];
        const rangecolor = checkLess ? [props.redColor, props.amberColor, greenColor] : [greenColor, props.amberColor, props.redColor];

        setRange({
            ticks: rangeticks,
            color: rangecolor,
            width: 12,
        });
    }, [props.metric && props.metric.value, props.config, props.amberColor, props.redColor]);

    React.useEffect(() => {
        if (!props.metric) return;

        let val = parseInt(props.metric.value);
        let percentage = Math.round((val * 100) / props.config.maximum);

        debuglog('Gauge value: ', percentage / 100);

        setValue(percentage / 100);
    }, [props.metric]);

    const config: GaugeConfig = {
        percent: value,
        range: range,
        indicator: {
            pointer: {
                style: {
                    stroke: labelColor,
                },
            },
            pin: {
                style: {
                    stroke: labelColor,
                    fill: labelColor,
                },
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => (isNaN(percent * 100) ? '-' : `${(percent * 100).toFixed(0)}%`),
                style: {
                    fontSize: '36px',
                    lineHeight: '36px',
                    color: labelColor,
                },
            },
        },
        gaugeStyle: {
            lineCap: 'round',
        },
    };

    return (
        <Card
            style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }}
            bodyStyle={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
            }}
        >
            <Text style={{ textAlign: 'center' }}>{props.config.label}</Text>
            <Text style={{ textAlign: 'center' }} type="secondary">
                {queueName}
            </Text>
            <Gauge {...config} className={`${blink ? 'blink' : ''}`} />

            <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />
        </Card>
    );
}

export default GaugeWidget;
