import { WarningOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Popconfirm, Row, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetConfig, WidgetType } from '../components/WidgetGrid';
import WidgetSettingsForm from '../components/WidgetSettingsForm';
import { debuglog } from '../scripts/debugLog';
import { ChartWidgetConfig } from '../widgets/chart/ChartWidget';
import { GaugeWidgetConfig } from '../widgets/gauge/GaugeWidget';
import { LabelWidgetConfig } from '../widgets/label/LabelWidget';
import { MetricWidgetConfig } from '../widgets/metric/MetricWidget';
import { TableWidgetConfig } from '../widgets/table/TableWidget';
import { AgentModel, AgentWidgetConfig } from '../widgets/agent/AgentWidget';

interface EditWidgetModalProps {
    currentWidget: WidgetConfig;
    isVisible: boolean;
    handleOk: any;
    handleCancel: any;
    handleDelete: any;
    availableMetrics: string[];
    availableSlaThresholds: string[];
    queueList: { name: string; id: string }[];
    availableAlarms: string[];
    openAlarmModal: () => void;
    availableAgents: AgentModel[];
    cacheUpdating: boolean;
}

/**
 * The Edit Widget modal. Opens when editing an existing widget within the Widget Grid.
 */
function EditWidgetModal(props: EditWidgetModalProps) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    async function handleOk() {
        try {
            var newConfig: WidgetConfig = props.currentWidget;
            const values = await form.validateFields();

            debuglog('Success', values);

            if (props.currentWidget && props.currentWidget.type === WidgetType.Label) {
                newConfig.config = {
                    metricid: '',
                    queues: [],
                    label: values.labeltext,
                    size: values.labelsize,
                    hidebackground: values.hidebackground,
                } as LabelWidgetConfig;
            } else if (props.currentWidget && props.currentWidget.type === WidgetType.Metric) {
                newConfig.config = {
                    metricid: values.metricid,
                    queues: values.queues,
                    label: values.metriclabel,
                    slaThreshold: values.slaThreshold,
                    amberThreshold: values.metricamber,
                    redThreshold: values.metricred,
                    amberSound: values.metricambersound,
                    redSound: values.metricredsound,
                    blink: values.metricblink,
                    sla: '',
                } as MetricWidgetConfig;
            } else if (props.currentWidget && props.currentWidget.type === WidgetType.Chart) {
                newConfig.config = {
                    metricid: values.metricid,
                    queues: values.queues,
                    label: values.chartlabel,
                    slaThreshold: values.slaThreshold,
                    timerange: values.charttimerange,
                } as ChartWidgetConfig;
            } else if (props.currentWidget && props.currentWidget.type === WidgetType.Table) {
                newConfig.config = {
                    metricid: values.metricid,
                    queues: values.queues,
                    label: values.tablelabel,
                    slaThreshold: values.slaThreshold,
                    selectedMetrics: values.metricids,
                    columnConfigs: (props.currentWidget.config as TableWidgetConfig).columnConfigs,
                    valuesize: values.valuesize,
                    headingsize: values.headingsize,
                } as TableWidgetConfig;
            } else if (props.currentWidget && props.currentWidget.type === WidgetType.Gauge) {
                newConfig.config = {
                    metricid: values.metricid,
                    queues: values.queues,
                    label: values.gaugelabel,
                    slaThreshold: values.slaThreshold,
                    maximum: values.gaugemax,
                    amberThreshold: values.metricamber,
                    redThreshold: values.metricred,
                    amberSound: values.metricambersound,
                    redSound: values.metricredsound,
                    blink: values.metricblink,
                    sla: '',
                } as GaugeWidgetConfig;
            } else if (props.currentWidget && props.currentWidget.type === WidgetType.Agent) {
                newConfig.config = {
                    metricid: values.metricid,
                    queues: values.queues,
                    label: values.agentlabel,
                    selectedAgents: values.agents,
                    showAgentID: values.showagentid,
                    valuesize: values.valuesize,
                    headingsize: values.headingsize,
                } as AgentWidgetConfig;
            }

            props.handleOk(newConfig);
        } catch (errorInfo) {
            debuglog('Failed:', errorInfo);
        }
    }

    // Build initial form values on modal open:
    React.useEffect(() => {
        if (props.currentWidget && props.currentWidget.type === WidgetType.Label) {
            form.setFieldsValue({
                labeltext: (props.currentWidget?.config as LabelWidgetConfig)?.label || '',
                labelsize: (props.currentWidget?.config as LabelWidgetConfig)?.size || '',
                hidebackground: (props.currentWidget?.config as LabelWidgetConfig)?.hidebackground || false,
            });
        } else if (props.currentWidget && props.currentWidget.type === WidgetType.Metric) {
            form.setFieldsValue({
                metricid: (props.currentWidget?.config as MetricWidgetConfig)?.metricid || '',
                queues: (props.currentWidget?.config as MetricWidgetConfig)?.queues || [],
                metriclabel: (props.currentWidget?.config as MetricWidgetConfig)?.label || '',
                slaThreshold: (props.currentWidget?.config as MetricWidgetConfig)?.slaThreshold || '',
                metricamber: (props.currentWidget?.config as MetricWidgetConfig)?.amberThreshold || 0,
                metricred: (props.currentWidget?.config as MetricWidgetConfig)?.redThreshold || 0,
                metricambersound: (props.currentWidget?.config as MetricWidgetConfig)?.amberSound || '',
                metricredsound: (props.currentWidget?.config as MetricWidgetConfig)?.redSound || '',
                metricblink: (props.currentWidget?.config as MetricWidgetConfig)?.blink || false,
            });
        } else if (props.currentWidget && props.currentWidget.type === WidgetType.Chart) {
            form.setFieldsValue({
                metricid: (props.currentWidget?.config as ChartWidgetConfig)?.metricid || '',
                queues: (props.currentWidget?.config as ChartWidgetConfig)?.queues || [],
                chartlabel: (props.currentWidget?.config as ChartWidgetConfig)?.label || '',
                slaThreshold: (props.currentWidget?.config as ChartWidgetConfig)?.slaThreshold || '',
                charttimerange: (props.currentWidget?.config as ChartWidgetConfig)?.timerange || 15,
            });
        } else if (props.currentWidget && props.currentWidget.type === WidgetType.Table) {
            form.setFieldsValue({
                metricid: (props.currentWidget?.config as TableWidgetConfig)?.metricid || '',
                queues: (props.currentWidget?.config as TableWidgetConfig)?.queues || [],
                tablelabel: (props.currentWidget?.config as TableWidgetConfig)?.label || '',
                slaThreshold: (props.currentWidget?.config as TableWidgetConfig)?.slaThreshold || '',
                metricids: (props.currentWidget?.config as TableWidgetConfig)?.selectedMetrics || [],
                valuesize: (props.currentWidget?.config as TableWidgetConfig)?.valuesize || 24,
                headingsize: (props.currentWidget?.config as TableWidgetConfig)?.headingsize || 16,
            });
        } else if (props.currentWidget && props.currentWidget.type === WidgetType.Gauge) {
            form.setFieldsValue({
                metricid: (props.currentWidget?.config as GaugeWidgetConfig)?.metricid || '',
                queues: (props.currentWidget?.config as GaugeWidgetConfig)?.queues || [],
                gaugelabel: (props.currentWidget?.config as GaugeWidgetConfig)?.label || '',
                slaThreshold: (props.currentWidget?.config as GaugeWidgetConfig)?.slaThreshold || '',
                gaugemax: (props.currentWidget?.config as GaugeWidgetConfig)?.maximum || 100,
                metricamber: (props.currentWidget?.config as GaugeWidgetConfig)?.amberThreshold || 0,
                metricred: (props.currentWidget?.config as GaugeWidgetConfig)?.redThreshold || 0,
                metricambersound: (props.currentWidget?.config as GaugeWidgetConfig)?.amberSound || '',
                metricredsound: (props.currentWidget?.config as GaugeWidgetConfig)?.redSound || '',
                metricblink: (props.currentWidget?.config as GaugeWidgetConfig)?.blink || false,
            });
        } else if (props.currentWidget && props.currentWidget.type === WidgetType.Agent) {
            form.setFieldsValue({
                metricid: (props.currentWidget?.config as AgentWidgetConfig)?.metricid || '',
                queues: (props.currentWidget?.config as AgentWidgetConfig)?.queues || [],
                label: (props.currentWidget?.config as AgentWidgetConfig)?.label || '',
                showagentid: (props.currentWidget?.config as AgentWidgetConfig)?.showAgentID || true,
                agents: (props.currentWidget?.config as AgentWidgetConfig)?.selectedAgents || [],
                valuesize: (props.currentWidget?.config as AgentWidgetConfig)?.valuesize || 24,
                headingsize: (props.currentWidget?.config as AgentWidgetConfig)?.headingsize || 16,
            });
        }
    }, [props.currentWidget]);

    return (
        <Modal
            title={t('editwidgetmodal.title', { widgetid: props.currentWidget?.widgetid })}
            visible={props.isVisible}
            onOk={handleOk}
            onCancel={props.handleCancel}
            destroyOnClose={true}
            forceRender={true}
            footer={[
                <Row key="editwidgetfooter" justify="space-between">
                    <Popconfirm
                        key="deleteconfirm"
                        title={t('editwidgetmodal.footer.delete.confirm')}
                        onConfirm={props.handleDelete}
                        okText={t('common.yes')}
                        cancelText={t('common.cancel')}
                        placement="topLeft"
                        icon={<WarningOutlined style={{ color: 'red' }} />}
                    >
                        <Button key="delete" type="primary" danger>
                            {t('editwidgetmodal.footer.delete.label')}
                        </Button>
                    </Popconfirm>
                    <Space>
                        <Button key="back" onClick={props.handleCancel}>
                            {t('common.cancel')}
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk}>
                            {t('common.save')}
                        </Button>
                    </Space>
                </Row>,
            ]}
        >
            <Form form={form} layout="vertical" name="editWidgetConfig">
                {WidgetSettingsForm({
                    widgetType: props.currentWidget ? props.currentWidget.type : null,
                    availableMetrics: props.availableMetrics,
                    availableSlaThresholds: props.availableSlaThresholds,
                    queueList: props.queueList,
                    currentWidget: props.currentWidget ? props.currentWidget : null,
                    availableAlarms: props.availableAlarms,
                    openAlarmModal: props.openAlarmModal,
                    availableAgents: props.availableAgents,
                    cacheUpdating: props.cacheUpdating,
                })}
            </Form>
        </Modal>
    );
}

export default EditWidgetModal;

// <!-- Modal - update the current threshold for thie box on THIS queue only, change queue, change thresholds-->
// <div class="modal" id="updateThresholdModal" role="dialog">
//   <div class="modal-dialog">
//     <!-- Modal content-->
//     <div class="modal-content">
//       <div class="modal-header">
//         <h4 class="modal-title">Threshold configuration</h4>
//       </div>
//       <div>

//       </div>
//       <form id="thresholdModalForm">
//         <div class="card text-white bg-danger o-hidden h-80" style="width:80%; margin:auto; padding-left:5px">Input
//           as relevant, if minutes use 30, 60, 90 </div>
//         <div class="modal-body">
//           <div class="form-group">
//             <div class="form-label-group">
//               <input name="thresholdYellow" type="text" id="thresholdYellow" class="form-control" placeholder="" autofocus="autofocus" value=>
//               <label for="thresholdYellow">Turn Amber at: </label>
//             </div>
//           </div>
//           <div class="form-group">
//             <div class="form-label-group">
//               <input name="thresholdRed" type="text" id="thresholdRed" class="form-control" placeholder="">
//               <input name="hiddenIdThresholdName" type="text" id="hiddenIdThresholdName" class="form-control" placeholder="" style="display:none">
//               <label for="thresholdRed">Turn red at:</label>
//             </div>

//             <div class="checkbox">
//               <label>
//                 <input name="blinker" id="blinker" type="checkbox">
//                 <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>
//                 Blink when red?
//               </label>
//             </div>
//           </div>

//           <div id="SLAConfig" style="display:none" class="form-group">
//             <label for="SLAConfig">SLA threshold</label>
//             <select name="SLAConfig" type="text" class="form-control" id="slalevels">
//               <option value="" disabled selected>Select a threshold</option>
//               <option value="15">15</option>
//               <option value="20">20</option>
//               <option value="25">25</option>
//               <option value="30">30</option>
//               <option value="45">45</option>
//               <option value="60">60</option>
//               <option value="90">90</option>
//               <option value="120">120</option>
//               <option value="180">180</option>
//               <option value="240">240</option>
//               <option value="300">300</option>
//               <option value="600">600</option>
//             </select>
//           </div>
//         </div>
//         </form?>
//         <div class="modal-footer">
//           <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
//           <button type="button" class="btn btn-primary" name="action" id="saveThresholdBtn" value="Save">Save</button>
//         </div>
//     </div>
//   </div>
// </div>
