import * as React from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Card, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import WidgetButtons from '../../components/WidgetButtons';
import { WidgetDataConfig } from '../../components/WidgetGrid';

const { Text } = Typography;

export interface LabelWidgetConfig extends WidgetDataConfig {
    size: number;
    hidebackground: boolean;
}

interface LabelWidgetProps {
    id: string;
    config: LabelWidgetConfig;
    editing: boolean;
    onEditWidgetClicked: any;
}
function LabelWidget(props: LabelWidgetProps) {
    const { t } = useTranslation();

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    return (
        <Card
            style={{
                height: '100%',
                cursor: props.editing ? 'move' : 'default',
                backgroundColor: props.config.hidebackground && 'transparent',
                borderColor: props.config.hidebackground && 'transparent',
            }}
            bodyStyle={{ height: '100%', overflow: 'hidden', padding: '0' }}
        >
            <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Text style={{ fontSize: props.config.size }}>{props.config.label}</Text>
            </Row>

            <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />
        </Card>
    );
}

export default LabelWidget;
