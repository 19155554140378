/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { debuglog } from './debugLog';

let socket; // REQUIRED holds socket information
let socketId; // REQUIRED needed to update the billing database with this Connect instance information
//Production Link wss://bwg3l1ru65.execute-api.ap-southeast-2.amazonaws.com/prod
// Dev link wss://sq1c7rykx7.execute-api.ap-southeast-2.amazonaws.com/dev
const WallboardSocketAPI = process.env.NODE_ENV === 'production' ? 'wss://bwg3l1ru65.execute-api.ap-southeast-2.amazonaws.com/prod' : 'wss://sq1c7rykx7.execute-api.ap-southeast-2.amazonaws.com/dev';
debuglog(process.env.NODE_ENV);

// List of in progress socket calls to prevent call collisions
let socketCalls = {};

export async function getAgentConfig(instanceURL, ssoURL) {
    console.log('WALLBOARD INITIALISATION - START');
    debuglog('WALLBOARD INITIALISATION - START');

    // Uninitialise CCP in case it is already initialised
    connect.core.terminate();

    // Get the connect instance information, via the logged in agent
    const agentConfig = await initConnect(instanceURL, ssoURL).catch((error) => {
        console.error('Connect Login Error', error);
    });
    debuglog('ARN', agentConfig);
    debuglog('Steams initialisation finished.  Terminating');
    connect.core.terminate();
    const iframe = document.getElementById('ccp-container-div').firstElementChild; // assumes there's nothing else in the container
    document.getElementById('ccp-container-div').removeChild(iframe);

    return agentConfig;
}

// initialize the streams api for connect instance information
async function initConnect(instanceURL, ssoURL) {
    // initialize the ccp
    return new Promise(async (resolve, reject) => {
        if (!instanceURL) {
            console.error('Instance URL error: ', instanceURL);
            reject(instanceURL);
        } else {
            debuglog('initCCP connect instance', instanceURL);

            connect.core.initCCP(document.getElementById('ccp-container-div'), {
                ccpUrl: instanceURL + 'connect/ccp-v2/', // REQUIRED TODO check for slashes on input
                loginPopup: true, // optional, defaults to 'true'
                loginPopupAutoClose: true, // optional, defaults to 'false'
                loginUrl: !ssoURL ? undefined : ssoURL,
                loginOptions: {
                    // optional, if provided opens login in new window
                    autoClose: true, // optional, defaults to 'false'
                    height: 800, // optional, defaults to 578
                    width: 600, // optional, defaults to 433
                    top: 0, // optional, defaults to 0
                    left: 0, // optional, defaults to 0
                },
                softphone: {
                    allowFramedSoftphone: true, // optional, defaults to false
                },
            });

            resolve(
                await new Promise(async (resolve, _reject) => {
                    connect.agent(async (agent) => {
                        if (agent) {
                            resolve(agent.getConfiguration());
                        } else {
                            debuglog('No agent!');
                            reject('No agent!');
                        }
                    });
                }),
            );
        }
    });
}

export async function openSocket(connectInstanceARN, connectCallback, disconnectCallback, cacheUpdatingCallback, cacheUpdatedCallback) {
    if (!connectInstanceARN) return;

    // Use promise to allow async usage:
    return new Promise((resolve, _reject) => {
        socket = new WebSocket(WallboardSocketAPI);
        debuglog(socket);

        // When the socket is connected...
        socket.onopen = async (e) => {
            debuglog('Connection opened!', socket, e);
            const socketId = await emitData('getConnectionId', null, connectInstanceARN); // get the socketID from the API end
            await emitData('updateConnection', socketId, connectInstanceARN); // Update the billing information so we can bill properly

            debuglog('Connection complete!');
            connectCallback();
            resolve();
        };

        // Set Incoming message handler
        socket.onmessage = (e) => {
            debuglog('Message Received', e);
            onSocketMessage(e, cacheUpdatingCallback, cacheUpdatedCallback);
        };

        socket.onclose = async (e) => {
            debuglog('Connection closed', e);
            disconnectCallback();
        };
    });
}

export async function emitData(action, data, connectInstanceARN) {
    if (!socket) return;
    if (!connectInstanceARN) return;

    return new Promise((resolve, reject) => {
        const socketId = `${Date.now()}_${action}`;
        socketCalls[socketId] = resolve; // Save id and resolve function to list of calls
        debuglog(`socketCalls.emitData: INVOKE action: ${action}, data:`, data);
        try {
            socket.send(
                JSON.stringify({
                    action: action,
                    data: data,
                    ConnectInstanceARN: connectInstanceARN,
                    id: socketId,
                }),
            );
            debuglog('socketCalls.emitData: WAITING');
        } catch (error) {
            console.error('socketCalls.emitData: ERROR ', JSON.stringify(error));
            reject(error);
        }
    });
}

// Incoming message handler
async function onSocketMessage(e, cacheUpdatingCallback, cacheUpdatedCallback) {
    const messageData = JSON.parse(e.data);

    debuglog('messageData', messageData);

    // Handle cache updated
    if (messageData.action === 'CacheUpdated') {
        debuglog('Cache Updated!', messageData);
        cacheUpdatedCallback();
        return;
    }

    // Handle cache updating
    if (messageData.data?.action === 'CacheUpdating' || messageData.action === 'CacheUpdating') {
        debuglog('Cache Updating...', messageData);
        cacheUpdatingCallback();
    }

    // Loop through in progress socket calls and resolve the correct one:
    for (const id in socketCalls) {
        debuglog(`Checking socketCall ${id}... `);
        if (messageData.id == id) {
            debuglog('socketCalls.emitData: END', messageData.data);
            socketCalls[id](messageData.data); // Call resolve()
            delete socketCalls[id]; // Remove this socket id
        }
    }
}

export function closeSocket() {
    debuglog('Closing socket...');
    socketCalls = {};
    socket.close();
}
