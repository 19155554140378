'use strict';

import { Form, Input, InputNumber, Switch } from 'antd';
import React from 'react';

export default function LabelWidgetSettings(t: any) {
    return (
        <div>
            <Form.Item
                label={t('widgetsettingsform.labelwidget.labeltext.label')}
                name="labeltext"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.labelwidget.labeltext.required'),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t('widgetsettingsform.labelwidget.labelsize.label')}
                name="labelsize"
                rules={[
                    {
                        required: true,
                        message: t('widgetsettingsform.labelwidget.labelsize.required'),
                    },
                ]}
            >
                <InputNumber min={12} max={48} />
            </Form.Item>

            <Form.Item
                label={t('widgetsettingsform.labelwidget.hidebackground.label')}
                name="hidebackground"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
        </div>
    );
}
