'use strict';

import { Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { MetricSelectionComponent, QueueSelectionComponent, ThresholdComponent } from '../../components/WidgetSettingsForm';

export default function GaugeWidgetSettings(
    t: any,
    availableMetrics: string[],
    availableSlaThresholds: string[],
    selectedMetric: string,
    setSelectedMetric: React.Dispatch<React.SetStateAction<string>>,
    setSelectedQueues: (value: string[]) => void,
    queueList: { name: string; id: string }[],
    selectedQueues: string[],
    availableAlarms: string[],
    openAlarmModal: () => void,
    cacheUpdating: boolean,
) {
    return (
        <div>
            {MetricSelectionComponent(t, availableMetrics, availableSlaThresholds, selectedMetric, setSelectedMetric)}

            {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues, cacheUpdating)}

            <Form.Item label={t('widgetsettingsform.gaugewidget.gaugelabel.label')} name="gaugelabel">
                <Input />
            </Form.Item>

            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        label={t('widgetsettingsform.gaugewidget.maximum.label')}
                        name="gaugemax"
                        rules={[
                            {
                                required: true,
                                message: t('widgetsettingsform.gaugewidget.maximum.required'),
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>

            <ThresholdComponent t={t} showAlarms={false} />
        </div>
    );
}
