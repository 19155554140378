import { Statistic } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';
import { debuglog } from '../scripts/debugLog';
import { ColumnConfig } from '../widgets/table/EditTableColumnModal';

export interface TimeSinceProps {
    now: number;
    timestamp: number;
    config: ColumnConfig;
    fontSize: number;
    redColor: string;
    amberColor: string;
}

export function formatTime(ms: number): string {
    const t = ms / 1000;
    const hrs = ~~(t / 3600);
    const mins = ~~((t % 3600) / 60);
    const secs = ~~t % 60;
    let ret = '';
    if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
}

/**
 * A small component showing time elapsed a specific timestamp.
 */
export function TimeSince(props: TimeSinceProps): React.ReactElement {
    const [displayTime, setDisplayTime] = useState<string>('');

    const [labelColor, setLabelColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [blink, setBlink] = useState(false);

    useEffect(() => {
        const timeSince = Date.now() - props.timestamp;

        debuglog('Time since: ', timeSince);

        const amber = props.config?.amberThreshold || 0;
        const red = props.config?.redThreshold || 0;
        const checkLess = red < amber;
        const val = timeSince;

        if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
            // Red triggered:
            setLabelColor(props.redColor);
            setBackgroundColor(tinycolor(props.redColor).setAlpha(0.2).toString());
            if (props.config?.blink) setBlink(true);
            else setBlink(false);
        } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
            // Amber triggered:
            setLabelColor(props.amberColor);
            setBackgroundColor(tinycolor(props.amberColor).setAlpha(0.2).toString());
            setBlink(false);
        } else {
            // No threshold:
            setLabelColor('');
            setBackgroundColor('');
            setBlink(false);
        }

        setDisplayTime(formatTime(timeSince));
        return;
    }, [props.timestamp, props.now]);

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: backgroundColor,
                padding: '8px',
            }}
            className={`data-widget ${blink ? 'blink' : ''}`}
        >
            <Statistic
                value={displayTime}
                style={{ textAlign: 'center' }}
                valueStyle={{
                    color: labelColor,
                    fontSize: props.fontSize,
                }}
            />
        </div>
    );
}

export default TimeSince;
