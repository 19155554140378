import * as React from 'react';

import useResizeObserver from 'use-resize-observer';
import GridLayout from 'react-grid-layout';
import '../styles/widgetgrid.css';
import 'react-resizable/css/styles.css';

import { Select, Spin, Typography } from 'antd';
import { DashboardOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;

// Widgets:
import MetricWidget, { MetricWidgetConfig } from '../widgets/metric/MetricWidget';
import LabelWidget, { LabelWidgetConfig } from '../widgets/label/LabelWidget';
import ChartWidget, { ChartWidgetConfig } from '../widgets/chart/ChartWidget';
import TableWidget, { TableWidgetConfig } from '../widgets/table/TableWidget';
import GaugeWidget, { GaugeWidgetConfig } from '../widgets/gauge/GaugeWidget';
import AgentWidget, { AgentModel, AgentWidgetConfig } from '../widgets/agent/AgentWidget';
import { ThemeColors } from '../modals/ModifyThemeModal';

// Localisation:
import { useTranslation } from 'react-i18next';
import { debuglog } from '../scripts/debugLog';
import { AgentContactsHandled } from 'src/types/EmitDataTypes';

export interface Metric {
    name: string;
    value: string;
    unit: string;
    statistic?: string;
    threshold?: string;
}

export interface AgentContact {
    contactId: string;
    state: string;
    channel: string;
    queueName: string;
}

export interface AgentMetric {
    id: string;
    username: string;
    name: string;
    email: string;
    status: string;
    timestamp: number;
    contacts: AgentContact[];
    handled: AgentContactsHandled;
}

export enum WidgetType {
    Metric,
    Label,
    Chart,
    Table,
    Gauge,
    Agent,
}

export interface WidgetConfig {
    widgetid: string;
    type: WidgetType;
    config: WidgetDataConfig;
}

export interface WidgetDataConfig {
    metricid: string;
    queues: string[];
    label: string;
    slaThreshold: string;
}

interface WidgetGridProps {
    widgetConfigs: WidgetConfig[];
    layout: GridLayout.Layout[];
    onLayoutChange: any;
    queueData: { [key: string]: Metric[] };
    editing: boolean;
    onEditWidgetClicked: any;
    themeColors: ThemeColors;
    darkMode: boolean;
    queueList: { name: string; id: string }[];
    currentQueues: string[][];
    queueSelected: (queue: string) => void;
    initComplete: boolean;
    triggerAlarm: (id: string, alarmSound: string) => void;
    dismissAlarm: (id: string) => void;
    agentList: AgentModel[];
    agentData: { [key: string]: AgentMetric };
}

/**
 * The meat of the wallboard. Loops through all the widget configs and displays them inside a editable grid.
 */
function WidgetGrid(props: WidgetGridProps) {
    const { t } = useTranslation();

    const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
    const [appeared, setAppeared] = React.useState(false);
    const widgetAppearDelay = 50;

    const [editingInner, setEditingInner] = React.useState(false);

    React.useEffect(() => {
        setAppeared(false);
        return;
    }, [props.widgetConfigs]);

    function buildWidget(config: WidgetConfig, index: number) {
        if (!config) return;
        if (!props?.queueData) return;

        switch (config.type) {
            case WidgetType.Metric:
                var metricConfig: MetricWidgetConfig = config.config as MetricWidgetConfig;
                var metric: Metric = aggregateMetrics(metricConfig.metricid, metricConfig.queues, metricConfig.slaThreshold);

                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }} data-grid={{ x: 0, y: 0, w: 2, h: 2 }}>
                        <MetricWidget
                            id={config.widgetid}
                            config={config.config as MetricWidgetConfig}
                            metric={metric}
                            editing={props.editing}
                            onEditWidgetClicked={props.onEditWidgetClicked}
                            amberColor={props.themeColors.amberColor}
                            redColor={props.themeColors.redColor}
                            queueList={props.queueList}
                            triggerAlarm={props.triggerAlarm}
                            dismissAlarm={props.dismissAlarm}
                        />
                    </div>
                );

            case WidgetType.Label:
                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }}>
                        <LabelWidget id={config.widgetid} config={config.config as LabelWidgetConfig} editing={props.editing} onEditWidgetClicked={props.onEditWidgetClicked} />
                    </div>
                );

            case WidgetType.Chart:
                var chartConfig: ChartWidgetConfig = config.config as ChartWidgetConfig;
                var metric: Metric = aggregateMetrics(chartConfig.metricid, chartConfig.queues, chartConfig.slaThreshold);

                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }} data-grid={{ x: 0, y: 0, w: 6, h: 6 }}>
                        <ChartWidget
                            id={config.widgetid}
                            config={config.config as ChartWidgetConfig}
                            metric={metric}
                            editing={props.editing}
                            onEditWidgetClicked={props.onEditWidgetClicked}
                            color={props.themeColors.primaryColor}
                            queueList={props.queueList}
                            darkMode={props.darkMode}
                        />
                    </div>
                );

            case WidgetType.Table:
                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }} data-grid={{ x: 0, y: 0, w: 6, h: 6 }}>
                        <TableWidget
                            id={config.widgetid}
                            config={config.config as TableWidgetConfig}
                            editing={props.editing}
                            onEditWidgetClicked={props.onEditWidgetClicked}
                            queueData={props.queueData}
                            queueList={props.queueList}
                            amberColor={props.themeColors.amberColor}
                            redColor={props.themeColors.redColor}
                            setEditingInner={setEditingInner}
                        />
                    </div>
                );

            case WidgetType.Gauge:
                var gaugeConfig: GaugeWidgetConfig = config.config as GaugeWidgetConfig;
                var metric: Metric = aggregateMetrics(gaugeConfig.metricid, gaugeConfig.queues, gaugeConfig.slaThreshold);

                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }} data-grid={{ x: 0, y: 0, w: 3, h: 6 }}>
                        <GaugeWidget
                            id={config.widgetid}
                            config={config.config as GaugeWidgetConfig}
                            metric={metric}
                            editing={props.editing}
                            onEditWidgetClicked={props.onEditWidgetClicked}
                            amberColor={props.themeColors.amberColor}
                            redColor={props.themeColors.redColor}
                            queueList={props.queueList}
                            triggerAlarm={props.triggerAlarm}
                            dismissAlarm={props.dismissAlarm}
                        />
                    </div>
                );

            case WidgetType.Agent:
                return (
                    <div key={config.widgetid} style={!appeared && { animationDelay: index * widgetAppearDelay + 'ms' }} data-grid={{ x: 0, y: 0, w: 3, h: 6 }}>
                        <AgentWidget
                            id={config.widgetid}
                            config={config.config as AgentWidgetConfig}
                            editing={props.editing}
                            onEditWidgetClicked={props.onEditWidgetClicked}
                            agentList={props.agentList}
                            agentData={props.agentData}
                            amberColor={props.themeColors.amberColor}
                            redColor={props.themeColors.redColor}
                            setEditingInner={setEditingInner}
                        />
                    </div>
                );

            default:
                return;
        }
    }

    function aggregateMetrics(metricid: string, queues: string[], threshold?: string): Metric {
        let metric: Metric = { name: metricid, unit: '', value: '-' };

        if (!queues || queues.length === 0 || !props.queueData[queues.toString()]) return metric;

        const queueData = props.queueData[queues.toString()];
        const metricData = threshold ? queueData.find((m) => m.name == metricid && m.threshold == threshold) : queueData.find((m) => m.name == metricid);

        if (metricData) return metricData;
        else return metric;
    }

    function buildGrid() {
        var items = props.widgetConfigs.map((key, i) => buildWidget(key, i));
        if (!appeared)
            window.setTimeout(() => {
                setAppeared(true);
            }, props.widgetConfigs.length * widgetAppearDelay + 400);
        debuglog(`Grid built: `, items);
        return items;
    }

    return (
        <div ref={ref}>
            <div>
                {!props.initComplete ? (
                    <div
                        style={{
                            width: '100%',
                            padding: '48px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '12px',
                        }}
                    >
                        <LoadingOutlined style={{ fontSize: '48px', opacity: 0.2 }} />

                        <Text style={{ textAlign: 'center' }}>{t('widgetgrid.fetchingdata')}</Text>
                    </div>
                ) : props.currentQueues.length == 0 ? (
                    <div
                        style={{
                            width: '100%',
                            padding: '48px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '12px',
                        }}
                    >
                        <DashboardOutlined style={{ fontSize: '48px', opacity: 0.2 }} />

                        <Text style={{ textAlign: 'center' }}>{t('widgetgrid.selectqueue')}</Text>

                        <Select
                            style={{ width: 240 }}
                            placeholder="Select Queue"
                            onSelect={props.queueSelected}
                            showSearch
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            notFoundContent={
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Spin size="small" />
                                </div>
                            }
                        >
                            {props.queueList.map((queue) => {
                                return (
                                    <Option key={queue.id} value={queue.id}>
                                        {queue.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                ) : (
                    <GridLayout
                        layout={props.layout}
                        onLayoutChange={props.onLayoutChange}
                        className="layout"
                        cols={12}
                        rowHeight={60}
                        width={width}
                        isResizable={props.editing}
                        isDraggable={props.editing && !editingInner}
                        compactType={null}
                        preventCollision
                    >
                        {buildGrid()}
                    </GridLayout>
                )}
            </div>
        </div>
    );
}

export default WidgetGrid;
