import { CommentOutlined, EditOutlined, FileTextOutlined, LoadingOutlined, PhoneOutlined, PlusSquareOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Space, Statistic, Table, Typography } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tinycolor from 'tinycolor2';
import { v4 as uuid } from 'uuid';
import TimeSince from '../../components/TimeSince';
import WidgetButtons from '../../components/WidgetButtons';
import { AgentMetric, WidgetDataConfig } from '../../components/WidgetGrid';
import { debuglog } from '../../scripts/debugLog';
import { ColumnConfig } from '../table/EditTableColumnModal';
import EditAgentColumnModal, { AgentColumnConfig } from './EditAgentColumnModal';
import EditAgentStatusColumnModal from './EditAgentStatusColumnModal';

const { Column } = Table;
const { Text } = Typography;

const availableColour = '#30bf78';
const busyColour = '#fa8c16';
const offlineColour = '#909090';

export interface AgentModel {
    id: string;
    username: string;
    identity?: {
        name: string;
        email: string;
    };
}

export interface AgentWidgetConfig extends WidgetDataConfig {
    selectedAgents: string[]; // as ids, not names
    showAgentID: boolean;
    valuesize: number;
    headingsize: number;
    columnConfigs: { [key: string]: AgentColumnConfig };
    timeInStatusConfig: ColumnConfig;
}

interface AgentWidgetProps {
    id: string;
    config: AgentWidgetConfig;
    editing: boolean;
    onEditWidgetClicked: any;
    agentList: AgentModel[];
    agentData: { [key: string]: AgentMetric };
    amberColor: string;
    redColor: string;
    setEditingInner: (v: boolean) => void;
}

function AgentWidget(props: AgentWidgetProps) {
    const { t } = useTranslation();

    function OnEditWidgetClicked() {
        props.onEditWidgetClicked(props.id);
    }

    const [dataSource, setDataSource] = useState<AgentMetric[]>([]);

    React.useEffect(() => {
        if (!props.agentData || !props.config.selectedAgents) return;

        let newDataSource: AgentMetric[] = [];

        props.config.selectedAgents.forEach((a) => {
            const agentModel = props.agentList.find((ag) => ag.id === a);
            if (!agentModel) return;

            let agent: AgentMetric = {
                id: a,
                username: agentModel.username,
                name: agentModel.identity?.name ?? '',
                email: agentModel.identity?.email ?? '',
                status: '-',
                timestamp: null,
                contacts: [],
                handled: null,
            };

            if (props.agentData[a]) {
                agent = props.agentData[a];
            }

            newDataSource.push(agent);
        });

        debuglog('Agent widget agentData:', props.agentData);
        debuglog('Agent widget data source:', newDataSource);

        setDataSource(newDataSource);

        return;
    }, [props.agentData, props.config.selectedAgents]);

    const [timeNow, setTimeNow] = useState(Date.now());

    useEffect(() => {
        function tick() {
            return setTimeout(() => {
                setTimeNow(Date.now());
            }, 1000);
        }
        const timeoutId = tick();
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeNow]);

    const [editStatusModalVisible, setEditStatusModalVisible] = useState<boolean>(false);
    //const [statusConfig, setStatusConfig] = useState<ColumnConfig>({ amberThreshold: null, redThreshold: null, blink: false });

    function saveStatusConfig(config: ColumnConfig) {
        if (config) {
            props.config.timeInStatusConfig = config;
        }
        props.setEditingInner(false);
        setEditStatusModalVisible(false);

        debuglog('Time in status', config);
    }

    function openEditStatusModal() {
        props.setEditingInner(true);
        setEditStatusModalVisible(true);
    }

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<{ key: string; config: AgentColumnConfig }>();

    function OnAddColumnClicked() {
        let newColConfigs = props.config.columnConfigs;

        if (!newColConfigs) {
            newColConfigs = {};
        }

        // Generate random id for column key until no collision:
        let key = uuid();
        while (newColConfigs[key]) {
            key = uuid();
        }

        newColConfigs[key] = { heading: '', metrics: [] };
        props.config.columnConfigs = newColConfigs;

        openEditModal(key);

        debuglog(`New Column (${key}):`, props.config.columnConfigs);
    }

    function saveColumnConfig(key: string, config: AgentColumnConfig) {
        if (key && config) {
            props.config.columnConfigs[key] = config;
        }
        props.setEditingInner(false);
        setEditModalVisible(false);
    }

    function deleteColumnConfig(key: string) {
        setSelectedColumn(null);
        delete props.config.columnConfigs[key];
        props.setEditingInner(false);
        setEditModalVisible(false);
    }

    function openEditModal(key: string) {
        if (!props.config.columnConfigs) props.config.columnConfigs = {};

        if (props.config.columnConfigs[key]) {
            setSelectedColumn({ key: key, config: props.config.columnConfigs[key] });
        } else {
            props.config.columnConfigs[key] = null;
            setSelectedColumn({ key: key, config: null });
        }
        props.setEditingInner(true);
        setEditModalVisible(true);
    }

    function renderCell(key: string, v: AgentMetric) {
        const config = props.config.columnConfigs[key];
        if (!config) return;

        let value = 0;

        config.metrics.forEach((m) => {
            switch (m) {
                case 'Inbound':
                    value += v.handled?.Inbound || 0;
                    break;
                case 'Outbound':
                    value += v.handled?.Outbound || 0;
                    break;
                case 'Callback':
                    value += v.handled?.Callback || 0;
                    break;
                case 'Transfer':
                    value += v.handled?.Transfer || 0;
                    break;
                case 'Total':
                    value += v.handled?.Total || 0;
                    break;
            }
        });

        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                }}
            >
                <Statistic
                    value={value}
                    style={{ textAlign: 'center' }}
                    valueStyle={{
                        fontSize: props.config.valuesize,
                    }}
                />
            </div>
        );
    }

    return (
        <Card
            style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }}
            bodyStyle={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
                padding: 0,
            }}
            bordered={false}
        >
            <Table
                dataSource={dataSource}
                size="middle"
                pagination={false}
                title={props.config.label ? () => <div style={{ fontSize: props.config.headingsize }}>{props.config.label}</div> : null}
                bordered
                rowKey="id"
                style={{ tableLayout: 'fixed' }}
            >
                <Column
                    title={() => {
                        return <Space style={{ padding: '16px', fontSize: props.config.headingsize }}>{t('widgets.agent.headers.agent')}</Space>;
                    }}
                    key="agent"
                    render={(v: AgentMetric, r, i) => {
                        let displayName = '';
                        if (props.config.showAgentID) {
                            displayName = v.name ? `${v.name} (${v.username})` : v.username;
                        } else {
                            displayName = v.name ? v.name : v.username;
                        }

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: props.config.valuesize,
                                    padding: '8px',
                                }}
                            >
                                {displayName}
                            </div>
                        );
                    }}
                />
                <Column
                    title={() => {
                        return <Space style={{ padding: '16px', fontSize: props.config.headingsize }}>{t('widgets.agent.headers.status')}</Space>;
                    }}
                    key="status"
                    render={(v: AgentMetric) => {
                        if (v.status === '-') {
                            return <LoadingOutlined />;
                        }

                        let labelColour = busyColour;
                        if (v.status === 'Available') {
                            labelColour = availableColour;
                        } else if (v.status === 'Offline') {
                            labelColour = offlineColour;
                        }

                        let backgroundColour = tinycolor(labelColour).setAlpha(0.2).toString();

                        return (
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: labelColour,
                                    backgroundColor: backgroundColour,
                                    fontSize: props.config.valuesize,
                                    padding: '8px',
                                }}
                            >
                                {v.status}
                            </div>
                        );
                    }}
                />
                <Column
                    title={() => {
                        return (
                            <Space style={{ padding: '16px', fontSize: props.config.headingsize }}>
                                {t('widgets.agent.headers.time')}
                                {props.editing && (
                                    <Button
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                        }}
                                        type="text"
                                        icon={<EditOutlined />}
                                        onClick={openEditStatusModal}
                                    />
                                )}
                            </Space>
                        );
                    }}
                    key="status"
                    render={(v: AgentMetric) => {
                        return v.timestamp && !isNaN(v.timestamp) ? (
                            <TimeSince
                                now={timeNow}
                                timestamp={v.timestamp}
                                config={props.config.timeInStatusConfig}
                                fontSize={props.config.valuesize}
                                redColor={props.redColor}
                                amberColor={props.amberColor}
                            />
                        ) : (
                            <Text type="secondary">-</Text>
                        );
                    }}
                />
                <Column
                    title={() => {
                        return <Space style={{ padding: '16px', fontSize: props.config.headingsize }}>{t('widgets.agent.headers.contacts')}</Space>;
                    }}
                    key="contacts"
                    render={(v: AgentMetric, r, i) => {
                        if (v.contacts && v.contacts.length > 0) {
                            return (
                                <div style={{ padding: '8px', fontSize: props.config.valuesize }}>
                                    {v.contacts.map((c) => {
                                        let icon = <FileTextOutlined />;
                                        if (c.channel === 'VOICE') icon = <PhoneOutlined />;
                                        else if (c.channel === 'CHAT') icon = <CommentOutlined />;
                                        return (
                                            <div key={c.contactId}>
                                                {icon} {c.queueName} - {t(`widgets.agent.contactstates.${c.state}`)}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        } else {
                            return (
                                <Text style={{ padding: '8px', fontSize: props.config.valuesize }} type="secondary">
                                    {t('widgets.agent.nocontacts')}
                                </Text>
                            );
                        }
                    }}
                />

                {props.config?.columnConfigs &&
                    Object.keys(props.config.columnConfigs).map((k, i) => {
                        return (
                            <Column
                                title={() => {
                                    return (
                                        <Space
                                            style={{
                                                padding: '16px',
                                                fontSize: props.config.headingsize,
                                            }}
                                        >
                                            {props.config.columnConfigs[k]?.heading}
                                            {props.editing && (
                                                <Button
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                    }}
                                                    type="text"
                                                    icon={<EditOutlined />}
                                                    onClick={() => openEditModal(k)}
                                                />
                                            )}
                                        </Space>
                                    );
                                }}
                                key={i}
                                render={(v, r, i) => {
                                    return renderCell(k, v);
                                }}
                            />
                        );
                    })}
            </Table>

            <WidgetButtons
                editing={props.editing}
                buttons={[
                    { icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked },
                    { icon: <PlusSquareOutlined />, tooltip: t('widgets.agent.addcolumn'), onClick: OnAddColumnClicked },
                ]}
            />

            <EditAgentColumnModal
                isVisible={editModalVisible}
                handleOk={saveColumnConfig}
                handleCancel={() => saveColumnConfig(null, null)}
                handleDelete={deleteColumnConfig}
                selectedColumn={selectedColumn}
            />

            <EditAgentStatusColumnModal isVisible={editStatusModalVisible} handleOk={saveStatusConfig} handleCancel={() => saveStatusConfig(null)} selectedColumn={props.config.timeInStatusConfig} />
        </Card>
    );
}

export default AgentWidget;
